import { Link, Stack } from "@mui/material";
import { useAuthContext } from "auth";
import NextLink from "next/link";
import { HeaderInnerHeight } from "src/components/header/header-container";
import { useGetHomePagePath } from "src/hooks/use-get-home-page-path";
import { Logo } from "../common/logo";

export const HeaderGableLogo: React.FC<{
  linkToMarketingIfLoggedOut?: boolean;
}> = ({ linkToMarketingIfLoggedOut }) => {
  const homePagePath = useGetHomePagePath();
  const auth = useAuthContext();

  const logoLink =
    linkToMarketingIfLoggedOut && auth.isNotLoggedIn
      ? `${process.env.NEXT_PUBLIC_MARKETING_BASE}/`
      : homePagePath;
  return (
    <Stack
      direction="row"
      alignItems="center"
      height={HeaderInnerHeight}
      pt="2.5px"
    >
      <NextLink href={logoLink} passHref legacyBehavior>
        <Link underline="none">
          <Logo />
        </Link>
      </NextLink>
    </Stack>
  );
};
