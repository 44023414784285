import { useMemo } from "react";
import useIsHost from "src/hooks/use-is-host";
import { useAuthContext } from "../../auth";

export function useGetHomePagePath(): string {
  const auth = useAuthContext();
  const isHost = useIsHost();

  return useMemo(() => {
    return auth.isNotLoggedIn
      ? "/login"
      : isHost
      ? "/host"
      : auth.user?.type === "individualGuest"
      ? "/explore"
      : "/me/welcome";
  }, [auth.isNotLoggedIn, auth.user?.type, isHost]);
}
