import { useMemo } from "react";
import { useGetUserAndCompany } from "src/queries/get-user";
import { CompanyAdminType } from "../types/company-types";
import { CompanyAdmin } from "src/apis/companies";

export function useIsCompanyOwner(): boolean {
  const { data: { user, company } = {} } = useGetUserAndCompany();
  return useMemo(() => {
    if (!user || !company) {
      return false;
    }
    return (
      company.admins.findIndex(
        (admin) =>
          admin.user === user._id &&
          admin.companyAdminType === CompanyAdminType.OWNER
      ) !== -1
    );
  }, [user, company]);
}

export function useIsCompanyBookingApprover(): boolean {
  const { data: { user, company } = {} } = useGetUserAndCompany();
  return useMemo(() => {
    if (!user || !company) {
      return false;
    }
    return (
      company.admins.findIndex(
        (admin) =>
          admin.user === user._id &&
          ((admin.companyAdminType === CompanyAdminType.OWNER &&
            admin.isBookingApprover !== false) ||
            admin.companyAdminType === CompanyAdminType.BOOKING_APPROVER)
      ) !== -1
    );
  }, [user, company]);
}

export function useGetUserCompanyAdmin(): CompanyAdmin | null {
  const { data: { user, company } = {} } = useGetUserAndCompany();
  return useMemo(() => {
    if (!user || !company) {
      return null;
    }

    return company.admins.find((admin) => admin.user === user._id);
  }, [company, user]);
}

export function useIsCompanyReceptionist(): boolean {
  const companyAdmin = useGetUserCompanyAdmin();

  return companyAdmin?.companyAdminType === CompanyAdminType.RECEPTIONIST;
}

export function useIsAssignedToOffices(): boolean {
  const companyAdmin = useGetUserCompanyAdmin();

  return !!companyAdmin && !!companyAdmin.offices?.length;
}

export function useIsCompanyOnBehalfBooker(): boolean {
  const { data: { user, company, computedPropsForUser } = {} } =
    useGetUserAndCompany();
  return useMemo(() => {
    if (!computedPropsForUser?.preferences.adminOnBehalfBookingEnabled) {
      return false;
    }
    const admin = company?.admins.find((admin) => admin.user === user?._id);

    return (
      admin?.companyAdminType === CompanyAdminType.BOOKER ||
      admin?.companyAdminType === CompanyAdminType.OWNER
    );
  }, [user, company, computedPropsForUser]);
}
