export type CancellationPolicyItem = {
  hoursBeforeMidnight: number;
};

export enum IntegrationSource {
  OfficeRnd = "officeRnd",
  Nexudus = "nexudus",
  MsExchange = "msExchange",
}

export type FlexSpaceIntegrationSource = Exclude<
  IntegrationSource,
  IntegrationSource.MsExchange
>;

export enum AcpConfigMode {
  reporting = "reporting",
  access = "access",
}

export enum AcpProvider {
  Brivo = "Brivo",
  Verkada = "Verkada",
}
