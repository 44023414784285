import { SvgIcon } from "@mui/material";
import * as React from "react";
import { IconProps } from "src/types/icon";

export const BikeRack: React.FC<IconProps> = ({
  variant = "contained",
  defaultFillColor = "white",
  htmlColor,
  ...props
}) => (
  <SvgIcon viewBox="0 0 18.3 11.8" {...props}>
    <path
      fill={htmlColor ?? "#8D2DF2"}
      d="M7.2,11.1h-1v-4c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v4h-1c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3
		h6.9c0.2,0,0.3-0.1,0.3-0.3S7.4,11.1,7.2,11.1z M5.6,7.1v4H1.9v-4c0-1,0.8-1.8,1.8-1.8C4.8,5.3,5.6,6.1,5.6,7.1z"
    />
    <g>
      <path
        fill={htmlColor ?? "#112B3C"}
        d="M3.5,9.9c-0.4,0-0.8-0.2-1.1-0.4L2.3,9.5v0.7l0,0c0.4,0.2,0.7,0.3,1.1,0.3c0.1,0,0.3,0,0.4,0
			c0.5,0,0.9-0.1,1.3-0.2l0,0V9.6L5.2,9.7C4.7,9.9,4.1,10,3.5,9.9z"
      />
      <path
        fill={htmlColor ?? "#112B3C"}
        d="M3.7,7.5C3.7,7.5,3.7,7.5,3.7,7.5C3.8,7.5,4,7.4,4,7.3L4.7,6l0,0C4.6,5.9,4.4,5.8,4.2,5.7l-0.1,0L3.5,7
			c-0.1,0.2,0,0.3,0.1,0.4C3.6,7.4,3.7,7.5,3.7,7.5L3.7,7.5L3.7,7.5L3.7,7.5z"
      />
      <path
        fill={htmlColor ?? "#112B3C"}
        d="M17.5,5.6C16.9,4.8,16,4.4,15,4.2c-0.6-0.1-1.2,0-1.8,0.3L12.3,3c0,0,0,0,0,0L13,1.8l0.6,0.1c0,0,0,0,0.1,0
			c0.2,0,0.3-0.1,0.3-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.2-0.2l-2.4-0.6c-0.2,0-0.3,0.1-0.4,0.2c0,0.2,0.1,0.3,0.2,0.4l1.2,0.3
			l-0.7,1.2L6.8,2.3l0.7-1.2l0,0v0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0l0,0h0c0,0,0,0,0,0
			L4.9,0C4.8,0,4.7,0,4.6,0c-0.1,0-0.1,0.1-0.1,0.2c0,0.2,0.1,0.3,0.2,0.4l2,0.5L5.5,3.4C5.1,3.2,4.7,3.1,4.3,3.1
			C2.2,2.9,0.4,4.4,0.1,6.4C0,7.3,0.3,8.3,0.8,9L1,9.2V8l0,0C0.8,7.5,0.7,7,0.8,6.5c0.2-1.7,1.7-3,3.4-2.8c0.3,0,0.6,0.1,0.9,0.3
			L4.9,4.5l0.1,0c0.1,0.1,0.3,0.1,0.4,0.2l0.1,0.1l0.3-0.5C6.6,5,7.1,6.1,7,7.2c0,0.4-0.2,0.8-0.4,1.2l0,0v1.1l0.1-0.1
			C7.2,8.7,7.5,8,7.6,7.2C7.8,5.9,7.2,4.5,6,3.7l0.4-0.7l3.8,2.4L9,7.6C8.9,7.7,9,7.9,9.1,8c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.2
			l1.2-2.1l0.5,0.3c-0.3,0.5-0.4,0.9-0.5,1.4c-0.1,1,0.2,2,0.8,2.8c0.6,0.8,1.5,1.3,2.5,1.4c0.1,0,0.3,0,0.4,0
			c0.9,0,1.7-0.3,2.3-0.8c0.8-0.6,1.3-1.5,1.4-2.5C18.4,7.4,18.1,6.4,17.5,5.6z M11.4,3.4l-0.8,1.5L7.4,3L11.4,3.4z M14.5,8.2
			L14.5,8.2c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.1,0,0.2,0c0.2-0.1,0.2-0.3,0.1-0.4l-1.6-2.8c0.5-0.2,0.9-0.2,1.4-0.2
			C15.7,5,16.5,5.4,17,6c0.5,0.7,0.8,1.5,0.7,2.3c-0.1,0.8-0.5,1.6-1.2,2.1c-0.7,0.5-1.5,0.8-2.3,0.7c-1.7-0.2-3-1.7-2.8-3.4
			c0-0.4,0.2-0.8,0.4-1.2L14.5,8.2z M12.2,6c0.2-0.2,0.4-0.4,0.7-0.6L13.9,7L12.2,6z M12.6,4.8c-0.4,0.2-0.7,0.5-0.9,0.8l-0.6-0.4
			L12,3.7L12.6,4.8z"
      />
    </g>
  </SvgIcon>
);
