import axios from "axios";
import Router from "next/router";
import {
  CSRF_HEADER_NAME,
  INVALID_CSRF_TOKEN_ERROR_REASON,
} from "src/types/csrf-types";

const baseURL =
  typeof window === "undefined" ? process.env.NEXT_PUBLIC_API_BASE : "/api/app";

export const USER_TIMEZONE_ID =
  typeof Intl !== "undefined"
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : undefined;

const csrfApi = axios.create({
  baseURL: "/api",
});

const api = axios.create({
  baseURL,
  headers: {
    "gable-accept": "application/json",
    "gable-timezone": USER_TIMEZONE_ID,
  },
});

async function initializeCsrfToken() {
  if (typeof window !== "undefined") {
    const response = await csrfApi.get<{ csrfToken: string }>("/csrf-token");
    api.defaults.headers[CSRF_HEADER_NAME] = response.data.csrfToken;
  }
}

api.interceptors.request.use((config) => {
  // This only works in the browser
  const pathName = typeof window !== "undefined" ? Router.pathname : "SSR";
  config.headers["gable-nextjs-pathname"] = pathName;
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status === 403 &&
      error.response.data?.reason === INVALID_CSRF_TOKEN_ERROR_REASON
    ) {
      const currentRetryCount = error.response.config.csrfRetryCount ?? 0;
      if (currentRetryCount >= 3) {
        return Promise.reject(error);
      }

      error.response.config.csrfRetryCount = currentRetryCount + 1;
      await initializeCsrfToken();
      return api(error.response.config);
    }
    return Promise.reject(error);
  }
);

initializeCsrfToken();

export default api;
