import { Close, Done } from "@mui/icons-material";
import MuiSwitch, { SwitchProps } from "@mui/material/Switch";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<
  { size: "small" | "large"; disabled?: boolean },
  "checked" | "track"
>()((theme, { size, disabled }, classes) => ({
  root: {
    width: size === "small" ? 30 : 42,
    height: size === "small" ? 20 : 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    "&&": {
      padding: 1,
      backgroundColor: theme.palette.white,
      color: theme.palette.paleLilac,
      width: size === "small" ? 16 : 23,
      height: size === "small" ? 16 : 23,
      top: size === "small" ? 2 : 1,
      left: 1,
      "&:hover": {
        backgroundColor: theme.palette.white,
        "@media (hover: none)": {
          backgroundColor: theme.palette.white,
        },
      },
      [`&.${classes.checked}`]: {
        transform: size === "small" ? "translateX(11px)" : "translateX(16px)",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.white,
        "&:hover": {
          backgroundColor: theme.palette.white,
          "@media (hover: none)": {
            backgroundColor: theme.palette.white,
          },
        },
        [`& + .${classes.track}`]: {
          backgroundColor: disabled
            ? theme.palette.paleGrey
            : theme.palette.electricPurple,
          opacity: 1,
          border: "none",
        },
      },
    },
  },
  icon: {
    fontSize: 12,
    color: disabled ? theme.palette.paleGrey : undefined,
  },
  track: {
    borderRadius: 16,
    border: `1px solid ${theme.palette.paleLilac}`,
    backgroundColor: theme.palette.paleLilac,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

const Switch: React.FC<{
  checked: boolean;
  onChange: SwitchProps["onChange"];
  disabled?: boolean;
  size?: "small" | "large";
}> = ({ checked, onChange, disabled, size = "large" }) => {
  const { classes } = useStyles({ size, disabled });
  return (
    <MuiSwitch
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      focusVisibleClassName={classes.focusVisible}
      checkedIcon={<Done className={classes.icon} />}
      icon={<Close className={classes.icon} />}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
};

export default Switch;
