import { useMemo } from "react";
import { useAuthContext } from "../../auth";

export default function useIsHost(): boolean {
  const auth = useAuthContext();
  return useMemo(() => {
    if (!auth.user) {
      return false;
    }
    return auth.user.type === "host" || auth.user.spaces?.length > 0;
  }, [auth.user]);
}
