import { SvgIcon } from "@mui/material";
import * as React from "react";
import { IconProps } from "src/types/icon";

export const Kitchen: React.FC<IconProps> = ({
  variant = "contained",
  defaultFillColor = "white",
  htmlColor,
  ...props
}) => (
  <SvgIcon viewBox="0 0 32 29" {...props}>
    <path
      d="M1 11C1 10.4477 1.44772 10 2 10H16V15H1V11Z"
      fill={variant === "contained" ? "#EC7965" : defaultFillColor}
    />
    <path
      d="M1 15.0322H31.9509"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3013 26.6598H28.9106"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.856 29H16.2822V9.99805H30.856C31.5095 9.99805 32.0393 10.5278 32.0393 11.1814V27.8166C32.0393 28.4702 31.5095 29 30.856 29Z"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8701 4.5506V3.64016C13.8701 2.18204 12.6543 1 11.1545 1C9.65474 1 8.43892 2.18204 8.43892 3.64016V9.99781"
      stroke={htmlColor ?? "#8D2DF2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1137 29H2.18335C1.52977 29 1 28.4702 1 27.8166V11.1814C1 10.5278 1.52977 9.99805 2.18335 9.99805H17.1137"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.2624 18.3294H21.1191"
      stroke={htmlColor ?? "#8D2DF2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.38721 22.3395V17.4917"
      stroke={htmlColor ?? "#8D2DF2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1508 22.2716V17.4238"
      stroke={htmlColor ?? "#8D2DF2"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.43945 28.7641V15.5498"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5044 9.41958V8.90325C18.5044 8.82407 18.5687 8.75977 18.6479 8.75977H21.66C21.7392 8.75977 21.8035 8.82407 21.8035 8.90325V9.41958"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6221 9.41958V8.90325C26.6221 8.82407 26.6864 8.75977 26.7655 8.75977H29.7777C29.8568 8.75977 29.9211 8.82407 29.9211 8.90325V9.41958"
      stroke={htmlColor ?? "#112B3C"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
