import api from "./root";
import { SpaceId } from "./spaces";

interface Options {
  firstName?: string;
  lastName?: string;
  phone?: string;
  contactInterest?: "guest" | "host" | "business";
  email: string;
  newsletter: boolean;
}

interface CompanyOptions {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  companyName: string;
  companySize?: string;
  phoneNumber?: string;
  context: string;
  weeklyVisits?: string;
  preferredLocations?: string;
}

export async function send(options: Options) {
  const response = await api.put(`/communications/contact`, {
    first_name: options.firstName,
    last_name: options.lastName,
    phone_number: options.phone,
    email: options.email,
    contact_interest: options.contactInterest,
    newsletter: options.newsletter,
  });
  return response.data;
}

export async function sendCompanyContact(options: CompanyOptions) {
  const response = await api.put(`/communications/contact/company`, {
    first_name: options.firstName,
    last_name: options.lastName,
    title: options.title,
    email: options.email,
    company_name: options.companyName,
    company_size: options.companySize,
    phone_number: options.phoneNumber,
    weekly_visits: options.weeklyVisits,
    preferred_locations: options.preferredLocations,
    context: options.context,
  });
  return response.data;
}

export interface HelpRequestOptions {
  email: string;
  subject: string;
  message: string;
}

export interface LocationRequestOptions {
  email: string;
  name: string;
  location: string;
  zipCode: string;
  needMeetingRoom: boolean;
  needDayPass: boolean;
  additionalDetails?: string;
}

export async function sendHelpRequest(options: HelpRequestOptions) {
  const response = await api.put(`/communications/contact/help`, {
    email: options.email,
    subject: options.subject,
    message: options.message,
  });
  return response.data;
}

export async function sendLocationRequest(options: LocationRequestOptions) {
  const response = await api.put(`/communications/contact/location`, options);
  return response.data;
}

export interface HostInquiryOptions {
  spaceId: SpaceId;
  date: string;
  seats: number;
  message: string;
}

export async function sendHostInquiry(options: HostInquiryOptions) {
  const response = await api.post("/bookings/host-inquiry", options);
}

export async function sendEventInquiry(options: unknown) {
  return (await api.post("/communications/request-event", options)).data;
}
