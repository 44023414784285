import { GableGrid } from "@gable/infra/gable-grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useAuthContext } from "../../../auth";
import { sendHelpRequest } from "../../apis/forms";
import Button from "./button";
import Typography from "./typography";

const schema = yup.object({
  email: yup.string().email().required(),
  subject: yup.string().required(),
  message: yup.string().required(),
});

export const HelpCenterForm: React.FC<{
  title?: string;
  defaultSubject?: string;
  defaultMessage?: string;
}> = ({ title, defaultSubject, defaultMessage }) => {
  const auth = useAuthContext();

  const {
    mutate: invoke,
    isError,
    isSuccess,
    isLoading,
  } = useMutation(sendHelpRequest);
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      email: auth.user?.email,
      subject: defaultSubject || "",
      message: defaultMessage || "",
    },
  });

  const handleHelpSubmit = (values) => {
    invoke(values);
  };

  return (
    <Box p={4} textAlign="center">
      <form onSubmit={handleSubmit(handleHelpSubmit)}>
        <GableGrid container direction="column" spacing={3}>
          <GableGrid
            item
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mb={2}>
              <img src="/email-support.svg" alt="" />
            </Box>
            <Typography variant="gableapph2small">
              {title || "Contact support"}
            </Typography>
          </GableGrid>
          <GableGrid item>
            <TextField
              fullWidth
              name="email"
              type="email"
              inputProps={{
                ref: register,
              }}
              placeholder="Email"
            />
          </GableGrid>
          <GableGrid item>
            <TextField
              fullWidth
              name="subject"
              inputProps={{
                ref: register,
              }}
              placeholder="Subject"
            />
          </GableGrid>
          <GableGrid item>
            <TextField
              fullWidth
              name="message"
              inputProps={{
                ref: register,
              }}
              placeholder="How can we help?"
              multiline
              maxRows={5}
              minRows={3}
            />
          </GableGrid>
          {isError && (
            <GableGrid item>
              <Typography color="error" align="left">
                An error occurred.
              </Typography>
            </GableGrid>
          )}
          <GableGrid item style={{ alignSelf: "center" }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              loading={isLoading}
              disabled={isSuccess}
            >
              {isSuccess ? "Successfully sent!" : "Send message"}
            </Button>
          </GableGrid>
        </GableGrid>
      </form>
    </Box>
  );
};
