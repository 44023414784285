import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { getUserWithCompany } from "../apis/users";

const USER_QUERY_KEY = "myUser";

function useGetUserCompanyDataWithSelect<TData>(
  select: (data: Awaited<ReturnType<typeof getUserWithCompany>>) => TData
) {
  const { status } = useSession();
  return useQuery([USER_QUERY_KEY], {
    queryFn: getUserWithCompany,
    enabled: status === "authenticated",
    select,
  });
}

function selectUserAndCompany(
  data: Awaited<ReturnType<typeof getUserWithCompany>>
) {
  return {
    user: data.user,
    company: data.company as Omit<typeof data.company, "preferences">,
    computedPropsForUser: data.computedPropsForUser,
  };
}

export function useGetUserAndCompany() {
  return useGetUserCompanyDataWithSelect(selectUserAndCompany);
}

useGetUserAndCompany.queryKey = USER_QUERY_KEY;

function selectCompany(data: Awaited<ReturnType<typeof getUserWithCompany>>) {
  return data.company as Omit<typeof data.company, "preferences">;
}

export function useGetCompany() {
  return useGetUserCompanyDataWithSelect(selectCompany);
}
useGetCompany.queryKey = USER_QUERY_KEY;

function selectCompanyPreferences(
  data: Awaited<ReturnType<typeof getUserWithCompany>>
) {
  return data.company?.preferences;
}

export function useGetRawCompanyPreferencesForAdmin() {
  return useGetUserCompanyDataWithSelect(selectCompanyPreferences);
}
