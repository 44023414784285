import {
  Alert,
  AlertProps,
  Box,
  LinearProgress,
  Link,
  Stack,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "auth";
import { useSession } from "next-auth/react";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { ripplingApi } from "src/apis/rippling";
import { PNG } from "src/assets";
import ActionDialog from "src/components/common/action-dialog";
import AppFooter from "src/components/common/app-footer";
import { HeadTitleTags } from "src/components/common/head-title-tags";
import { HelpCenterForm } from "src/components/common/help-center-form";
import Typography, { TypographyProps } from "src/components/common/typography";
import { ModalHeader } from "src/components/header/modal-header";
import { useIsCompanyOwner } from "src/hooks/use-is-company-admin";
import { useGetUserAndCompany } from "src/queries/get-user";
import { assertUnreachable } from "src/types/utils";
import {
  ActionPostAuthType,
  setActionPostAuth,
} from "src/utils/action-post-auth";
import {
  GableErrorDetails,
  getDataFromErrorResponse,
} from "src/utils/parse-error";

export const RIPPLING_PAGE_INSTALL_VARIANT = "install";

const StateInfo: React.FC<{
  severity: AlertProps["severity"];
  children: TypographyProps["children"];
}> = ({ severity = "info", children }) => (
  <Alert severity={severity} variant="outlined">
    <Typography variant="body1" whiteSpace="pre-line">
      {children}
    </Typography>
  </Alert>
);

const StateDetails = ({
  state,
  openSupportDialog,
  connectError,
  variant,
}: {
  state: "success" | "error" | "not_admin" | "no_company";
  openSupportDialog: () => void;
  connectError?: GableErrorDetails;
  variant: string;
}) => {
  const router = useRouter();
  switch (state) {
    case "not_admin":
      return (
        <StateInfo severity="info">
          Oops! Installation failed. Please ensure to log in as an admin and
          retry. Need help?{" "}
          <Link
            component="span"
            onClick={openSupportDialog}
            sx={{ cursor: "pointer" }}
          >
            Contact support
          </Link>
        </StateInfo>
      );
    case "no_company":
      return (
        <StateInfo severity="info">
          Company account needed for installation.{" "}
          <Link
            component="span"
            onClick={openSupportDialog}
            sx={{ cursor: "pointer" }}
          >
            Contact support
          </Link>{" "}
          for assistance and more details. Thank you!
        </StateInfo>
      );
    case "success":
      return (
        <StateInfo severity="success">
          {variant === RIPPLING_PAGE_INSTALL_VARIANT ? (
            <>
              Installation finished. you are about to redirect back to Rippling.{" "}
              <br />
              <Link href={router.query.redirect_uri as string}>click here</Link>
            </>
          ) : (
            "Installation finished."
          )}
        </StateInfo>
      );
    case "error":
      return (
        <StateInfo severity="error">
          Could not install the app, please try again. Error:{" "}
          {connectError.message}
        </StateInfo>
      );
    default:
      assertUnreachable(state);
      return null;
  }
};

const Rippling = () => {
  const queryClient = useQueryClient();
  const auth = useAuthContext();
  const { status: sessionStatus } = useSession();
  const router = useRouter();
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const { data: { user, company } = {}, isLoading: isUserCompanyLoading } =
    useGetUserAndCompany();

  const isCompanyOwner = useIsCompanyOwner();

  const {
    isLoading: isConnecting,
    mutate: runConnect,
    data: connectResult,
    error: connectErrorResponse,
  } = useMutation(ripplingApi.connect, {
    onSuccess: async () => {
      if (variant === RIPPLING_PAGE_INSTALL_VARIANT) {
        window.location.href = router.query.redirect_uri as string;
        return;
      }

      await queryClient.invalidateQueries([useGetUserAndCompany.queryKey]);
      return await router.push("/company/settings/integrations");
    },
  });

  const connectError = useMemo(
    () =>
      connectErrorResponse && getDataFromErrorResponse(connectErrorResponse),
    [connectErrorResponse]
  );

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (auth.isNotLoggedIn) {
      setActionPostAuth({
        actionType: ActionPostAuthType.ConnectRippling,
        data: { queryParams: router.query ?? {} },
      });
      router.push("/login");
    }
  }, [auth.isNotLoggedIn, router]);

  const variant =
    (router.query.variant as string) || RIPPLING_PAGE_INSTALL_VARIANT;
  const isLoading = !!(
    (isCompanyOwner && isConnecting) ||
    isUserCompanyLoading ||
    auth?.isNotLoggedIn ||
    !router?.isReady
  );

  useEffect(() => {
    if (!router.isReady || sessionStatus !== "authenticated") {
      return;
    }

    if (isCompanyOwner) {
      runConnect({
        code: router.query.code as string,
        variant,
      });
    }
  }, [
    isCompanyOwner,
    router.isReady,
    router.query,
    runConnect,
    sessionStatus,
    variant,
  ]);

  const state = useMemo<
    Parameters<typeof StateDetails>[0]["state"] | "connecting"
  >(() => {
    if (isCompanyOwner && isConnecting) {
      return "connecting";
    }

    if (company && !isCompanyOwner) {
      return "not_admin";
    }

    if (user && !company) {
      return "no_company";
    }

    if (connectResult) {
      return "success";
    }
    if (connectError) {
      return "error";
    }
  }, [
    company,
    connectError,
    connectResult,
    isCompanyOwner,
    isConnecting,
    user,
  ]);

  return (
    <>
      <HeadTitleTags title="Rippling Installation" />
      <ModalHeader onClose={() => router.push("/login")} />
      <Stack
        my={{ xs: 10, sm: 20 }}
        mx={5}
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Image src={PNG.rippling_fullLogoBlack} alt="Rippling" height={80} />
        <Typography variant="gableapph2">Rippling Installation</Typography>
        <Box pt={4} maxWidth={650} width="100%">
          {state === "connecting" ? (
            <>
              <LinearProgress sx={{ width: "100%" }} color="primary" />
              <Typography variant="body1">Installing in progress...</Typography>
            </>
          ) : isLoading ? (
            <LinearProgress sx={{ width: "100%" }} color="primary" />
          ) : (
            <StateDetails
              state={state}
              openSupportDialog={() => setIsSupportDialogOpen(true)}
              connectError={connectError}
              variant={variant}
            />
          )}
        </Box>
      </Stack>
      <ActionDialog
        open={isSupportDialogOpen}
        onClose={() => setIsSupportDialogOpen(false)}
      >
        <HelpCenterForm />
      </ActionDialog>
      <AppFooter />
    </>
  );
};

export default Rippling;
