export type DirectoryType =
  | "azure scim v2.0"
  | "bamboohr"
  | "breathe hr"
  | "generic scim v1.1"
  | "generic scim v2.0"
  | "gsuite directory"
  | "jump cloud scim v2.0"
  | "okta scim v1.1"
  | "okta scim v2.0"
  | "onelogin scim v2.0"
  | "pingfederate scim v2.0"
  | "rippling"
  | "workday";

export enum CompanyAdminType {
  OWNER = "OWNER",
  BOOKER = "BOOKER",
  BOOKING_APPROVER = "BOOKING_APPROVER",
  RECEPTIONIST = "RECEPTIONIST",
}

export enum CompanyBookingLimitInterval {
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export enum CompanyBookingLimitType {
  BUDGET = "BUDGET",
  SEATS = "SEATS",
}

export enum CompanyPolicyMode {
  RESTRICTIVE = "restrictive",
  PRIORITY = "priority",
}

export enum CompanyBookingLimitAggregationType {
  EMPLOYEE = "employee",
  TOTAL = "total",
}
