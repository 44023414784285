import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import Loading from "./loading/loading";

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
}

const useStyles = makeStyles<{ loading: boolean }>()((theme, { loading }) => ({
  root: {
    position: "relative",
    pointerEvents: loading ? "none" : undefined,
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    visibility: loading ? "hidden" : undefined,
  },
  loading: {
    position: "absolute",
  },
}));

export const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
  const { loading, children, color, variant, className, ...rest } = props;
  const { classes, cx } = useStyles({ loading });

  return (
    <MuiButton
      color={color}
      variant={variant}
      className={cx(classes.root, { [className]: !!className })}
      ref={ref}
      {...rest}
    >
      <div className={classes.buttonContent}>{children}</div>
      {loading && (
        <div className={classes.loading}>
          <Loading
            color={
              color === "primary" && variant === "contained"
                ? "#fff"
                : "electricPurple"
            }
          />
        </div>
      )}
    </MuiButton>
  );
});

Button.displayName = "Button";

export default Button;
