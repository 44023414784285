import { getCurrentScope, setUser } from "@sentry/nextjs";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGetCompany } from "src/queries/get-user";
import { useAuthContext } from "../../auth";
import { getCloudinaryUserAvatar } from "./common/utils";

export interface RouteChange {
  pathName: string;
  fullPath: string;
}

export interface RouteHistory {
  routeHistory: RouteChange[];
  previousRoute: (requestedPathName: string) => RouteChange;
}

const RouteHistoryContext = createContext<RouteHistory>({
  routeHistory: [],
  previousRoute: null,
});

const RouteHistoryProvider = (props) => {
  const { asPath, pathname } = useRouter();
  const auth = useAuthContext();
  const { data: company } = useGetCompany();

  const [routeHistory, setRouteHistory] = useState<RouteChange[]>([]);
  useEffect(() => {
    const newRouteHistory = [
      ...routeHistory,
      { pathName: pathname, fullPath: asPath },
    ];
    if (routeHistory.length > 20) {
      newRouteHistory.shift();
    }
    setRouteHistory(newRouteHistory);
  }, [asPath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (auth.user && (!auth.user.company || (auth.user.company && company))) {
      setUser(auth.user);
      analytics.identify(auth.user._id, {
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        email: auth.user.email,
        phone: auth.user.phone,
        signUpStage: auth.user.signUpStage,
        createdAt: auth.user.createdAt,
        emailVerified: auth.user.emailVerified,
        department: auth.user.companyProperties?.department,
        ...(company && {
          company: {
            company_id: company._id,
            name: company.name,
            servicePlan: company.paymentSettings?.selectedServicePlan,
          },
        }),
        avatar: {
          type: "avatar",
          image_url: getCloudinaryUserAvatar({ user: auth.user }),
        },
      });
    } else {
      getCurrentScope().setUser(null);
    }
  }, [auth.user, company]);

  const previousRoute = useCallback(
    (requestedPathName: string = "") => {
      for (let i = routeHistory.length - 1; i >= 0; i--) {
        if (requestedPathName) {
          if (routeHistory[i].pathName === requestedPathName) {
            return routeHistory[i];
          }
        } else if (routeHistory[i].pathName !== pathname) {
          return routeHistory[i];
        }
      }
      return null;
    },
    [routeHistory, pathname]
  );

  return (
    <RouteHistoryContext.Provider value={{ routeHistory, previousRoute }}>
      {props.children}
    </RouteHistoryContext.Provider>
  );
};

export function useRouteHistory() {
  return useContext(RouteHistoryContext);
}

export default RouteHistoryProvider;
