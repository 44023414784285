import { GableGrid } from "@gable/infra/gable-grid";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { Box, Container, Divider, Link, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";
import { makeStyles } from "tss-react/mui";
import Typography from "./typography";

const useStyles = makeStyles()((theme) => ({
  footerSocialIcon: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "50%",
    width: 40,
    height: 40,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const AppFooter = forwardRef<HTMLDivElement>((_, ref) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box marginTop="auto" zIndex={10} pt={2} ref={ref}>
      <Divider />
      <Box py={3}>
        <Container>
          <GableGrid container alignItems="center" spacing={3}>
            <GableGrid item xs={6} md={4}>
              <Typography variant="body2" color="textSecondary">
                © {new Date().getFullYear()} Gable
              </Typography>
            </GableGrid>
            <GableGrid
              item
              xs={12}
              md={4}
              style={{ order: matches ? -1 : undefined }}
            >
              <Box textAlign="center">
                <GableGrid container spacing={2} justifyContent="center">
                  <GableGrid item>
                    <a
                      className={classes.footerSocialIcon}
                      target="_blank"
                      href="https://www.facebook.com/gable.inc"
                      rel="noreferrer"
                    >
                      <Facebook color="inherit" />
                    </a>
                  </GableGrid>
                  <GableGrid item>
                    <a
                      className={classes.footerSocialIcon}
                      target="_blank"
                      href="https://twitter.com/gable_inc"
                      rel="noreferrer"
                    >
                      <Twitter color="inherit" />
                    </a>
                  </GableGrid>
                  <GableGrid item>
                    <a
                      className={classes.footerSocialIcon}
                      target="_blank"
                      href="https://www.linkedin.com/company/gable-inc/"
                      rel="noreferrer"
                    >
                      <LinkedIn color="inherit" />
                    </a>
                  </GableGrid>
                  <GableGrid item>
                    <a
                      className={classes.footerSocialIcon}
                      target="_blank"
                      href="https://www.instagram.com/trygable/"
                      rel="noreferrer"
                    >
                      <Instagram color="inherit" />
                    </a>
                  </GableGrid>
                </GableGrid>
              </Box>
            </GableGrid>
            <GableGrid item xs={6} md={4}>
              <GableGrid container justifyContent="flex-end" spacing={2}>
                <GableGrid item>
                  <Link
                    href={`${process.env.NEXT_PUBLIC_MARKETING_BASE}/privacy-policy`}
                    target="_blank"
                    color="textSecondary"
                    underline="hover"
                  >
                    Privacy
                  </Link>
                </GableGrid>
                <GableGrid item>
                  <Link
                    href={`${process.env.NEXT_PUBLIC_MARKETING_BASE}/terms-of-use`}
                    target="_blank"
                    color="textSecondary"
                    underline="hover"
                  >
                    Terms
                  </Link>
                </GableGrid>
              </GableGrid>
            </GableGrid>
          </GableGrid>
        </Container>
      </Box>
    </Box>
  );
});

AppFooter.displayName = "AppFooter";

export default AppFooter;
