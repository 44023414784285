import { Android, Apple, Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Head from "next/head";
import NextLink from "next/link";
import { HeadTitleTags } from "../common/head-title-tags";
import Triangle from "../marketing/triangle";
import { useRouteHistory } from "../route-history-provider";

function MobilePage() {
  const routeHistory = useRouteHistory();

  return (
    <>
      <HeadTitleTags title="Flex space and office management for distributed teams" />
      <Head>
        <meta
          name="description"
          content="Manage your hybrid workforce and empower your company through our flexible workspaces"
        />
      </Head>
      <div>
        <Box bgcolor="offWhite" pt={10} textAlign="center">
          <Triangle direction="bottom left" bgcolor="#fff" />
        </Box>
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            textAlign: "center",
            padding: 16,
          }}
        >
          <Box textAlign="right">
            <NextLink
              passHref
              href={
                routeHistory.previousRoute("")
                  ? routeHistory.previousRoute("").fullPath
                  : `${process.env.NEXT_PUBLIC_MARKETING_BASE}/`
              }
              legacyBehavior
            >
              <IconButton size="large">
                <Close />
              </IconButton>
            </NextLink>
          </Box>
          <Box mb={4} position="relative" zIndex={1}>
            <img src="/mobile.svg" alt="" />
          </Box>
          <Box mb={6} position="relative" zIndex={1}>
            <Typography variant="h5" gutterBottom>
              Gable isn't compatible with this browser yet
            </Typography>
            <Typography variant="body1">
              Please download our app to get the full Gable experience
            </Typography>
          </Box>

          <Box mb={2}>
            <Button
              fullWidth
              startIcon={<Apple />}
              variant="outlined"
              size="medium"
              href="https://apps.apple.com/us/app/gable-work-from-anywhere/id1500898270"
              target="_blank"
            >
              App Store
            </Button>
          </Box>
          <Button
            fullWidth
            startIcon={<Android />}
            variant="outlined"
            size="medium"
            href="https://play.google.com/store/apps/details?id=com.gable.mobile"
            target="_blank"
          >
            Play Store
          </Button>
        </Box>
      </div>
    </>
  );
}

export default MobilePage;
