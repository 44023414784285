import { Check, Remove } from "@mui/icons-material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<CheckboxIconProps>()(
  (theme, { color, bgColor }) => ({
    icon: {
      width: 22,
      height: 22,
      borderRadius: 6,
      border: `solid 1px ${color ?? theme.palette.coolGrey}`,
      fontSize: 14,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: bgColor ?? "unset",
    },
    checked: {
      border: `solid 1px ${color ?? theme.palette.electricPurple}`,
      backgroundColor: bgColor ?? theme.palette.electricPurple,
    },
  })
);

interface CheckboxIconProps {
  color?: string;
  bgColor?: string;
}

export const Icon: React.FC<CheckboxIconProps> = ({ color, bgColor }) => {
  const { classes } = useStyles({ color, bgColor });

  return <div className={classes.icon} />;
};

export const IndeterminateIcon: React.FC<CheckboxIconProps> = ({
  color,
  bgColor,
}) => {
  const { classes, cx } = useStyles({ color, bgColor });

  return (
    <div className={cx(classes.icon, classes.checked)}>
      <Remove fontSize="inherit" color="inherit" />
    </div>
  );
};

export const CheckedIcon: React.FC<CheckboxIconProps> = ({
  color,
  bgColor,
}) => {
  const { classes, cx } = useStyles({ color, bgColor });

  return (
    <div className={cx(classes.icon, classes.checked)}>
      <Check fontSize="inherit" color="inherit" />
    </div>
  );
};
