import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

enum StripeActionCompletionState {
  PENDING,
  NO_ACTION,
  STRIPE_ACTION_COMPLETED,
}

const inIframe = () => window.self !== window.top;

const StripeActionCompletion: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter();
  const [stripeActionCompleted, setStripeActionCompleted] = useState(
    StripeActionCompletionState.NO_ACTION
  );

  useEffect(() => {
    // This needs to run on the client only for SSR to work properly
    if (inIframe()) {
      setStripeActionCompleted(StripeActionCompletionState.PENDING);
    }
  }, []);

  useEffect(() => {
    if (!inIframe() || !router.isReady) {
      return;
    }
    const stripeFlowCompletedInIframe =
      !!router.query["stripe-action-completed"];
    if (stripeFlowCompletedInIframe) {
      window.top.postMessage(
        { stripeActionCompleted: true },
        window.location.origin
      );
      setStripeActionCompleted(
        StripeActionCompletionState.STRIPE_ACTION_COMPLETED
      );
      return;
    }
    setStripeActionCompleted(StripeActionCompletionState.NO_ACTION);
  }, [router.isReady]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {stripeActionCompleted === StripeActionCompletionState.NO_ACTION &&
        children}
    </>
  );
};

export default StripeActionCompletion;
