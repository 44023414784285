import api from "./root";

type IRipplingConfig = {
  companyId: string;
  ripplingCompanyId: string;
  ripplingCompanyName: string;
  ssoStatus: "active" | "inactive";
  lastSyncStartedAt: string;
  lastSyncFinishedAt: string;
  directorySyncStatus: string;
};

const connect = (options: { code: string; variant: string }) =>
  api.get<{ success: true }>("/rippling/connect", {
    params: options,
  });

const disconnect = () => api.post<void>("/rippling/disconnect");

const getConfig = async () => {
  const res = await api.get<{
    config?: IRipplingConfig;
    shouldReinstall: boolean;
    canStartSync: boolean;
  }>("/rippling/config");
  return res.data;
};

const syncDirectory = () => api.post<void>("/rippling/sync-directory");

const enableSSO = () => api.post<void>("/rippling/enable-sso");
const disableSSO = () => api.post<void>("/rippling/disable-sso");

const getSSOSettings = async () => {
  const res = await api.get<{
    connectionId: string;
    rawMetadata: string;
    assertionConsumerService: { Location: string; Binding: string }[];
    entityID: string;
    nameIDFormat: string;
    metadataUrl: string;
  }>("/rippling/sso-settings");
  return res.data;
};

const getAuthorizeUrl = async () => {
  const res = await api.get<{
    authorizeUrl: string;
  }>("/rippling/authorize-url");
  return res.data;
};

export const ripplingApi = {
  connect,
  disconnect,
  getConfig,
  enableSSO,
  disableSSO,
  getSSOSettings,
  getAuthorizeUrl,
  syncDirectory,
};
