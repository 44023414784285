import { isAfter } from "date-fns";
import type { Booking } from "src/apis/bookings";
import { SpaceContainer } from "src/apis/space-containers";
import type { Replace } from "src/types/utils";
import { SupportedCurrency } from "src/utils/currency";
import { User } from "../types/auth-types";
import { Company, CompanyPreferences } from "./companies";
import api from "./root";
import { Space } from "./spaces";
import { SupportedCurrencyData } from "./supported-currencies";

export type JoinableBookedSpaceOrHq = {
  date: string;
  bookings: { _id: string; guests: User[] }[];
} & (
  | {
      hq: SpaceContainer;
      space?: never;
    }
  | {
      space: Space;
      hq?: never;
    }
);

interface UpdatePersonalDetailsOptions {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  interests?: string[];
  preferredLocations?: string[];
  objectives?: string[];
  workstyles?: string[];
  department?: string;
  password?: string;
  policyAgreed?: boolean;
  isIncognito?: boolean;
  companyName?: string;
  jobTitle?: string;
}

export async function updateUser(options: UpdatePersonalDetailsOptions) {
  const {
    firstName,
    lastName,
    email,
    phone,
    interests,
    objectives,
    workstyles,
    department,
    password,
    policyAgreed,
    isIncognito,
    preferredLocations,
    companyName,
    jobTitle,
  } = options;
  const response = await api.post<{ token: string; user: User }>("/users", {
    firstName,
    lastName,
    email,
    phone,
    interests,
    objectives,
    workstyles,
    department,
    password,
    policyAgreed,
    isIncognito,
    preferredLocations,
    companyName,
    jobTitle,
  });
  return response.data;
}

export async function forgotPassword({ email }: { email: string }) {
  const response = await api.post("/auth/password/reset/initiate", {
    email,
  });
  return response.data;
}

export async function getUserWithCompany() {
  const response = await api.get<{
    user: User;
    company: Company | null;
    computedPropsForUser?: {
      preferences: CompanyPreferences;
      paymentSettings: {
        invoiceChargeCurrency?: SupportedCurrency;
        canChooseCurrency: boolean;
      };
      currencies: SupportedCurrencyData[];
    };
  }>("/users/with-company");
  return response.data;
}

export async function resetPassword({
  password,
  token,
}: {
  password: string;
  token: string;
}) {
  const response = await api.post("/auth/password/reset", {
    password,
    token,
  });
  return response.data;
}

export async function emailVerify(options: { token: string }) {
  const response = await api.post("/auth/email/verify", {
    token: options.token,
  });
}

export async function resendEmailVerification() {
  const response = await api.post("/users/email/send-verification");
  return response.data;
}

export async function uploadProfilePhoto({ file }) {
  const formData = new FormData();
  formData.append("profile-pic", file);
  const response = await api.post("/photos/profile", formData);
  return response.data;
}

export async function getTeammatesJoinableBookedSpaces() {
  const response = await api.get<{
    joinableBookedSpacesOnDates: JoinableBookedSpaceOrHq[];
  }>("/users/teammates-joinable-booked-spaces");

  return response.data;
}

export type TeammateBooking = Replace<
  Booking,
  { space: Space<{ populateSpaceContainer: true }> }
>;

export type Teammate = Pick<
  User,
  | "_id"
  | "firstName"
  | "chosenProfilePic"
  | "uploadedProfilePic"
  | "linkedinProfilePic"
  | "facebookProfilePic"
  | "companyProperties"
>;
export async function getTeammatesBookedSpaces(
  params: {
    startDate: string;
    endDate: string;
  } & ({ teammateIds?: string[] } | { page?: number; pageSize?: number })
) {
  const response = await api.get<{
    teammatesBookedSpaces: Record<User["_id"], TeammateBooking[]>;
    teammates: Teammate[];
    hasNextPage: boolean;
  }>("/users/teammates-booked-spaces", { params });

  return response.data;
}

export function shouldForceUserToVerifyEmail(user: User) {
  return (
    !!user &&
    !user.emailVerified &&
    isAfter(new Date(user.createdAt), new Date(2021, 8, 13))
  );
}

export function isDepartmentSetByDirectory(user: User) {
  return (
    !!user &&
    user.company &&
    user.companyProperties?.areValuesSetByDirectory &&
    !!user.companyProperties.department
  );
}

export async function changePassword(options: {
  oldPassword: string;
  newPassword: string;
}): Promise<void> {
  return api.post("/users/change-password", options);
}
