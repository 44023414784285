import { SvgIcon } from "@mui/material";
import * as React from "react";
import { IconProps } from "src/types/icon";

export const WiFi: React.FC<IconProps> = ({
  variant = "contained",
  defaultFillColor = "white",
  htmlColor,
  ...props
}) => (
  <SvgIcon viewBox="0 0 28 25" {...props}>
    <path
      fill={htmlColor ?? "#3E484E"}
      fillRule="nonzero"
      d="M19.6 9.613c.245 0 .45.177.492.41l.008.09v6.44c0 .276-.224.5-.5.5-.246 0-.45-.177-.492-.41l-.008-.09v-6.44c0-.276.223-.5.5-.5z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
    <path
      fill={variant === "contained" ? "#EC7965" : defaultFillColor}
      d="M19.598 9.801c.906 0 1.641-.735 1.641-1.64 0-.907-.735-1.64-1.64-1.64-.906 0-1.642.733-1.642 1.64 0 .905.736 1.64 1.641 1.64z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
    <path
      fill={htmlColor ?? "#8D2DF2"}
      fillRule="nonzero"
      d="M19.598 6.02c-1.182 0-2.14.958-2.14 2.14 0 1.182.959 2.141 2.14 2.141 1.182 0 2.141-.959 2.141-2.14 0-1.183-.959-2.14-2.14-2.14zm0 1c.63 0 1.141.51 1.141 1.14 0 .63-.511 1.141-1.14 1.141-.63 0-1.142-.511-1.142-1.14 0-.63.511-1.14 1.141-1.14z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
    <path
      fill={htmlColor ?? "#8D2DF2"}
      fillRule="nonzero"
      d="M19.598 2.629c1.934 0 3.665 1.129 4.462 2.854.116.25.006.548-.244.663-.251.116-.548.007-.664-.244-.634-1.374-2.013-2.273-3.554-2.273-1.567 0-2.963.929-3.582 2.339-.111.253-.406.368-.66.257-.252-.112-.367-.407-.256-.66.778-1.77 2.53-2.936 4.498-2.936z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
    <path
      fill={htmlColor ?? "#8D2DF2"}
      fillRule="nonzero"
      d="M19.598-.5c2.496 0 4.783 1.244 6.154 3.275.154.229.094.54-.135.694-.23.154-.54.094-.694-.135C23.736 1.576 21.758.5 19.598.5s-4.135 1.074-5.323 2.83c-.155.23-.465.29-.694.135-.23-.155-.29-.465-.135-.694C14.818.74 17.103-.5 19.598-.5z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
    <path
      fill={htmlColor ?? "#3E484E"}
      fillRule="nonzero"
      d="M25.566 16.053H.898c-.772 0-1.398.626-1.398 1.399v4.932c0 .773.626 1.399 1.398 1.399h24.668c.772 0 1.398-.626 1.398-1.399v-4.932c0-.773-.626-1.399-1.398-1.399zm-24.668 1h24.668c.22 0 .398.178.398.399v4.932c0 .22-.178.399-.398.399H.898c-.22 0-.398-.178-.398-.399v-4.932c0-.221.178-.399.398-.399z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
    <path
      fill={htmlColor ?? "#3E484E"}
      fillRule="nonzero"
      d="M3.235 18.52c.245 0 .45.177.492.41l.008.09v1.797c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41l-.008-.09V19.02c0-.276.224-.5.5-.5zM5.848 18.52c.246 0 .45.177.492.41l.008.09v1.797c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41l-.008-.09V19.02c0-.276.224-.5.5-.5zM8.462 18.52c.246 0 .45.177.492.41l.008.09v1.797c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41l-.008-.09V19.02c0-.276.224-.5.5-.5zM18.752 19.418c.276 0 .5.224.5.5 0 .246-.177.45-.41.492l-.09.008h-1.797c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492l.09-.008h1.797zM23.163 19.418c.276 0 .5.224.5.5 0 .246-.177.45-.41.492l-.09.008h-1.797c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492l.09-.008h1.797z"
      transform="translate(-162 -1207) translate(162.791 1207.79)"
    />
  </SvgIcon>
);
