import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from "@mui/material/Typography";
import React, { forwardRef } from "react";
import { makeStyles } from "tss-react/mui";
import { GableColor, GableVariant } from "../../theme";
import { UnwrappedForwardRef } from "../../types/utils";

const useStyles = makeStyles<Pick<TypographyProps, "variant" | "color">>()(
  (theme, { variant, color }) => ({
    root: {
      ...theme.typography[variant],
      color: theme.palette[color]
        ? typeof theme.palette[color] === "string"
          ? theme.palette[color]
          : theme.palette[color].main
        : undefined,
    },
  })
);

export interface TypographyProps
  extends Omit<MuiTypographyProps, "variant" | "component" | "color" | "ref"> {
  variant?: MuiTypographyProps["variant"] | GableVariant;
  component?: React.ElementType;
  color?: "primary" | "textSecondary" | "error" | GableColor;
}

export const Typography = forwardRef<
  UnwrappedForwardRef<MuiTypographyProps["ref"]>,
  TypographyProps
>((props, ref) => {
  const { className, variant, color, ...rest } = props;
  const { classes, cx } = useStyles({ variant, color });
  return (
    <MuiTypography
      {...rest}
      ref={ref}
      className={cx(classes.root, {
        [className]: !!className,
      })}
    />
  );
});
Typography.displayName = "GableTypography";

export default Typography;
