import { Box, Stack, useScrollTrigger } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";

export const HeaderInnerHeight = 52;
const AppHeaderPadding = 16;
export const HeaderHeight = HeaderInnerHeight + AppHeaderPadding * 2;

export interface HeaderContainerProps {
  transparent?: boolean;
  disableShadow?: boolean;
  bgColor?: string;
}

export const HeaderContainer = forwardRef<
  HTMLDivElement,
  HeaderContainerProps & { children: React.ReactNode }
>(({ transparent, disableShadow, children, bgColor }, ref) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const theme = useTheme();
  const isTransparent = trigger || transparent;

  return (
    <Box
      bgcolor={isTransparent ? theme.palette.white : bgColor || undefined}
      zIndex={theme.zIndex.drawer + 1}
      position="sticky"
      top={0}
      width="100%"
      py={`${AppHeaderPadding}px`}
      boxShadow={isTransparent && !disableShadow ? theme.shadows[1] : undefined}
      sx={{ transition: theme.transitions.create("background-color") }}
      ref={ref}
    >
      <Box marginLeft="auto" marginRight="auto" px={{ xs: 2, sm: 4, md: 6 }}>
        {children}
      </Box>
    </Box>
  );
});

HeaderContainer.displayName = "HeaderContainer";

export const HeaderContentContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {children}
    </Stack>
  );
};
