import { SpaceBookingType } from "../../../apis/spaces";
import { SpaceTypeValues } from "../../../constants";

export const JOIN_BOOKING_QUERY_KEY = "join-booking";
export const JOIN_BOOKING_V2_QUERY_KEY = "v2";
export const REQUEST_LOCATION_QUERY_KEY = "request-location";
export const REQUEST_EVENT_QUERY_KEY = "request-event";

export type Filters = {
  amenities: string[];
  interests: string[];
  date: string;
  seats: number;
  minPrice?: number;
  maxPrice?: number;
  city: string;
  spaceTypes: SpaceTypeValues[];
  spaceBookingTypes: readonly SpaceBookingType[];
  lat?: number;
  lng?: number;
  zoom?: number;
  available?: boolean;
  dogFriendly?: boolean;
  autoApproval?: boolean;
  view?: "map" | "gallery";
  originatedFromWizardFlowId?: string;
  companyFavorites?: boolean;
  [JOIN_BOOKING_QUERY_KEY]?: boolean;
  [JOIN_BOOKING_V2_QUERY_KEY]?: boolean;
  [REQUEST_LOCATION_QUERY_KEY]?: boolean;
  [REQUEST_EVENT_QUERY_KEY]?: boolean;
};
const PLACE_ID_URL_SLUG_PREFIX = "p_";

export const placeIdToUrlSlug = (placeId: string) =>
  PLACE_ID_URL_SLUG_PREFIX + placeId;

export const isPlaceIdUrlSlug = (urlSlug: string) =>
  !!urlSlug?.startsWith(PLACE_ID_URL_SLUG_PREFIX);

export const getPlaceIdFromUrlSlug = (urlSlug: string) =>
  isPlaceIdUrlSlug(urlSlug)
    ? urlSlug.substring(PLACE_ID_URL_SLUG_PREFIX.length)
    : null;

export const MAP_MOVED_SLUG = "map";
export const MAP_AREA_LOCATION = {
  urlSlug: MAP_MOVED_SLUG,
  formattedName: "Map area",
  timeZoneId: null,
} as const;

export const isMapArea = (urlSlug: string) => urlSlug === MAP_MOVED_SLUG;
