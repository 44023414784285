import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import { CookieDataCollectionConsent } from "src/components/cookie-consent/cookie-data-collection-consent";
import { createEmotionSsrAdvancedApproach } from "tss-react/next/pagesDir";
import AuthContextProvider from "../auth";
import StripeActionCompletion from "../src/components/app/booking/stripe-action-completion";
import { isMobileDevice } from "../src/components/common/utils";
import MobilePage from "../src/components/mobile/mobile";
import RouteHistoryProvider from "../src/components/route-history-provider";
import theme from "../src/theme";

const isSignupFlowSupportedInMobile = false;

const MOBILE_SUPPORTED_ROUTES = [
  "/explore/[[...city]]",
  "/spaces/[id]/[slug]",
  "/email-verify",
  "/checkin",
  "/survey",
  "/me/reservations/arrival-guide/[id]",
  "/site-access-result",
  "/visit-hq/[containerId]/walk-in",
  "/visit-hq/[containerId]/walk-in/[visitId]",
  "/visit-hq/[containerId]/register/[visitId]",
  ...(isSignupFlowSupportedInMobile ? ["/signup", "/login"] : []),
];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: 1 * 60 * 1000 },
  },
});
const IsMobileViewContext = React.createContext(false);

interface AppPropsWithErr extends AppProps<{ session?: Session }> {
  err: any;
}

const Gable = ({
  Component,
  pageProps: { session, ...pageProps } = {},
  err,
}: AppPropsWithErr) => {
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }, []);

  const isMobileView = isMobileDevice();
  const router = useRouter();
  const shouldShowMobileSplash = (() => {
    if (MOBILE_SUPPORTED_ROUTES.includes(router.pathname)) return false;

    if (
      isSignupFlowSupportedInMobile &&
      (router.asPath.startsWith("/onboarding") ||
        router.asPath.startsWith("/explore") ||
        router.asPath.startsWith("/password"))
    ) {
      return false;
    }

    return isMobileView;
  })();

  const component = <Component {...pageProps} err={err} />;
  const canonicalUrl = `https://www.gable.to${router.asPath.split("?")[0]}`;
  return (
    <>
      <StripeActionCompletion>
        <Head>
          <title>Gable</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
          <link rel="canonical" href={canonicalUrl} key="canonicalUrl" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:type" content="website" key="og-type" />
          <meta name="apple-itunes-app" content="app-id=id1500898270" />
        </Head>
        <QueryClientProvider client={queryClient}>
          <IsMobileViewContext.Provider value={isMobileView}>
            {/* Refresh cookie once an hour */}
            <SessionProvider session={session} refetchInterval={60 * 60}>
              <AuthContextProvider>
                <RouteHistoryProvider>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      {shouldShowMobileSplash ? (
                        <>
                          <Box
                            display="none"
                            flexDirection="column"
                            minWidth={1400}
                          >
                            {component}
                          </Box>
                          <MobilePage />
                        </>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection="column"
                          height={isMobileView ? "100%" : undefined}
                          minHeight={
                            isMobileView ? "-webkit-fill-available" : "100dvh"
                          }
                        >
                          {component}
                        </Box>
                      )}
                      <CookieDataCollectionConsent />
                      <ReactQueryDevtools />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </RouteHistoryProvider>
              </AuthContextProvider>
            </SessionProvider>
          </IsMobileViewContext.Provider>
        </QueryClientProvider>
      </StripeActionCompletion>
    </>
  );
};

export function useIsMobileViewContext() {
  return useContext(IsMobileViewContext);
}

const { augmentDocumentWithEmotionCache, withAppEmotionCache } =
  createEmotionSsrAdvancedApproach({ key: "css" });

export { augmentDocumentWithEmotionCache };

export default withAppEmotionCache(Gable);
