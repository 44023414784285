import { AxiosError } from "axios";

export interface GableErrorDetails {
  reason: string;
  message?: string;
  metadata?: unknown;
  data: string[];
}

export function getDataFromErrorResponse(
  error: AxiosError<GableErrorDetails> | unknown
) {
  return (error as AxiosError<GableErrorDetails>)?.response?.data;
}

export function isAxiosNotFoundError(err: unknown) {
  return err instanceof AxiosError && err.response?.status === 404;
}
