import api from "./root";

export interface GeoLocation {
  _id: string;
  latitude: number;
  longitude: number;
  formattedName: string;
  city: string;
  cityLowercase: string;
  cityShortName: string;
  state?: string;
  stateLowercase?: string;
  stateShortName?: string;
  country: string;
  countryShortName: string;
  timeZoneId: string;
  isHidden: boolean;
  aliases: string[];
  urlSlug: string;
  zone: GeoZone;
  googlePlaceId: string;
  googleViewport?: {
    northeast: {
      lat: number;
      lng: number;
    };
    southwest: {
      lat: number;
      lng: number;
    };
  };
}

export interface LocationSuggestion {
  description: string;
  secondaryText: string;
  googlePlaceId: string;
}

export enum GeoZone {
  UnitedStates = "United States",
  Canada = "Canada",
  EMEA = "EMEA",
  LATAM = "Latin America",
  APAC = "APAC",
  Other = "Other",
}

export const ALL_GEO_ZONES = Object.values(GeoZone);

export const GeoZoneLabels = {
  [GeoZone.UnitedStates]: "United States",
  [GeoZone.Canada]: "Canada",
  [GeoZone.EMEA]: "EMEA",
  [GeoZone.LATAM]: "Latin America",
  [GeoZone.APAC]: "APAC",
  [GeoZone.Other]: "Rest of World",
} as const satisfies Record<GeoZone, string>;

interface Options {
  getAllKnownLocations?: boolean;
}

export async function getLocations(options: Options) {
  const response = await api.get(`/locations`, { params: options });
  return response.data.locations as GeoLocation[];
}

export enum GeoType {
  all = "all",
  regions = "regions",
  cities = "cities",
}

export async function getSuggestedLocations(options: {
  term: string;
  sessionToken: string;
  geoType: GeoType;
}) {
  const response = await api.get<{
    countries: LocationSuggestion[];
    states: LocationSuggestion[];
    cities: LocationSuggestion[];
    unknowns: LocationSuggestion[];
    allResults: LocationSuggestion[];
  }>("/locations/suggestions", { params: options });
  return response.data;
}

export enum GooglePlaceType {
  street_address = "street_address",
  route = "route",
  intersection = "intersection",
  political = "political",
  country = "country",
  administrative_area_level_1 = "administrative_area_level_1",
  administrative_area_level_2 = "administrative_area_level_2",
  administrative_area_level_3 = "administrative_area_level_3",
  administrative_area_level_4 = "administrative_area_level_4",
  administrative_area_level_5 = "administrative_area_level_5",
  administrative_area_level_6 = "administrative_area_level_6",
  administrative_area_level_7 = "administrative_area_level_7",
  colloquial_area = "colloquial_area",
  locality = "locality",
  sublocality = "sublocality",
  neighborhood = "neighborhood",
  premise = "premise",
  subpremise = "subpremise",
  plus_code = "plus_code",
  postal_code = "postal_code",
  natural_feature = "natural_feature",
  airport = "airport",
  park = "park",
  point_of_interest = "point_of_interest",
  street_number = "street_number",
}

export const googlePlaceTypesWithinCity = [
  GooglePlaceType.street_address,
  GooglePlaceType.route,
  GooglePlaceType.colloquial_area,
  GooglePlaceType.locality,
  GooglePlaceType.sublocality,
  GooglePlaceType.intersection,
  GooglePlaceType.neighborhood,
  GooglePlaceType.premise,
  GooglePlaceType.subpremise,
  GooglePlaceType.plus_code,
  GooglePlaceType.postal_code,
  GooglePlaceType.airport,
  GooglePlaceType.park,
  GooglePlaceType.point_of_interest,
];

export type GooglePlaceData = {
  coordinates: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
  placeId: string;
  address: string;
  types: GooglePlaceType[];
  city?: string;
  state?: string;
  country?: string;
};

export async function getGooglePlaceData(options: { placeId: string }) {
  const response = await api.get<GooglePlaceData>("/geocode/place-geocode", {
    params: options,
  });
  return response.data;
}

export const shouldShowCountryInsteadOfState = (country: string) =>
  !!(country && country.toLowerCase() !== "united states");
