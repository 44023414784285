import { forwardRef } from "react";
import { HeaderCloseSection } from "./header-close-section";
import {
  HeaderContainer,
  HeaderContainerProps,
  HeaderContentContainer,
} from "./header-container";
import { HeaderGableLogo } from "./header-gable-logo";

export const ModalHeader = forwardRef<
  HTMLDivElement,
  HeaderContainerProps & {
    onClose: () => void;
    showLoginPrompt?: boolean;
  }
>(
  (
    { onClose, bgColor, disableShadow, transparent, showLoginPrompt = false },
    ref
  ) => {
    return (
      <HeaderContainer
        transparent={transparent}
        bgColor={bgColor}
        disableShadow={disableShadow}
        ref={ref}
      >
        <HeaderContentContainer>
          <HeaderGableLogo />
          <HeaderCloseSection
            onClose={onClose}
            showLoginPrompt={showLoginPrompt}
          />
        </HeaderContentContainer>
      </HeaderContainer>
    );
  }
);

ModalHeader.displayName = "ModalHeader";
