import { NextRouter } from "next/router";
import { RIPPLING_PAGE_INSTALL_VARIANT } from "pages/rippling/[variant]";
import { ParsedUrlQuery } from "querystring";
import {
  REQUEST_EVENT_QUERY_KEY,
  REQUEST_LOCATION_QUERY_KEY,
} from "src/components/app/explore/utils";
import { assertUnreachable } from "src/types/utils";

const localStorageKey = "actionPostAuth";

export enum ActionPostAuthType {
  JoinBooking = 0,
  GoToReservations = 2,
  FillOutSurvey = 3,
  ConnectSlack = 4,
  ConnectMsTeams = 5,
  RequestLocation = 6,
  ConnectRippling = 7,
  HqPage = 8,
  myVisits = 9,
  RequestEvent = 10,
  EventRsvp = 11,
}

export interface JoinBookingPostAuthData {
  bookingId: string;
  isV2: boolean;
}

export interface GoToReservationsPostAuthData {}

export interface FillOutSurveyPostAuthData {
  queryParams: ParsedUrlQuery;
}

export interface ConnectSlackPostAuthData {
  queryParams: ParsedUrlQuery;
}

export interface ConnectMsTeamsPostAuthData {
  queryParams: ParsedUrlQuery;
}

export interface ConnectRipplingPostAuthData {
  queryParams: ParsedUrlQuery;
}

export interface RequestLocationPostAuthData {}

export interface HqPagePostAuthData {
  hqId: string;
}

export interface MyVisitsPagePostAuthData {
  queryParams: ParsedUrlQuery;
}

interface ActionPostAuthBase {
  actionType: ActionPostAuthType;
}

export interface ActionPostAuthJoinBooking extends ActionPostAuthBase {
  actionType: ActionPostAuthType.JoinBooking;
  data: JoinBookingPostAuthData;
}

export interface ActionPostAuthGoToReservations extends ActionPostAuthBase {
  actionType: ActionPostAuthType.GoToReservations;
}

export interface ActionPostAuthSurvey extends ActionPostAuthBase {
  actionType: ActionPostAuthType.FillOutSurvey;
  data: FillOutSurveyPostAuthData;
}

export interface ActionPostAuthSlack extends ActionPostAuthBase {
  actionType: ActionPostAuthType.ConnectSlack;
  data: ConnectSlackPostAuthData;
}

export interface ActionPostAuthMsTeams extends ActionPostAuthBase {
  actionType: ActionPostAuthType.ConnectMsTeams;
  data: ConnectMsTeamsPostAuthData;
}

export interface ActionPostAuthRippling extends ActionPostAuthBase {
  actionType: ActionPostAuthType.ConnectRippling;
  data: ConnectRipplingPostAuthData;
}

export interface ActionPostAuthRequestLocation extends ActionPostAuthBase {
  actionType: ActionPostAuthType.RequestLocation;
  data: RequestLocationPostAuthData;
}

export interface ActionPostAuthEventRequest extends ActionPostAuthBase {
  actionType: ActionPostAuthType.RequestEvent;
}

export interface ActionPostAuthHqPage extends ActionPostAuthBase {
  actionType: ActionPostAuthType.HqPage;
  data: HqPagePostAuthData;
}

export interface ActionPostAuthMyVisitsPage extends ActionPostAuthBase {
  actionType: ActionPostAuthType.myVisits;
  data: MyVisitsPagePostAuthData;
}

export interface ActionPostAuthEventsRsvpPage extends ActionPostAuthBase {
  actionType: ActionPostAuthType.EventRsvp;
  eventId: string;
  state: string;
}

export type ActionPostAuth =
  | ActionPostAuthJoinBooking
  | ActionPostAuthGoToReservations
  | ActionPostAuthSurvey
  | ActionPostAuthSlack
  | ActionPostAuthMsTeams
  | ActionPostAuthRippling
  | ActionPostAuthRequestLocation
  | ActionPostAuthHqPage
  | ActionPostAuthMyVisitsPage
  | ActionPostAuthEventRequest
  | ActionPostAuthEventsRsvpPage;

export function setActionPostAuth(actionPostAuth: ActionPostAuth) {
  localStorage.setItem(localStorageKey, JSON.stringify(actionPostAuth));
}

export function getActionPostAuth(): ActionPostAuth {
  const savedValue = localStorage.getItem(localStorageKey);
  if (savedValue) {
    return JSON.parse(savedValue);
  }
  return null;
}

export function clearActionPostAuth() {
  localStorage.removeItem(localStorageKey);
}

export function handleActionPostAuth(
  actionPostAuth: ActionPostAuth,
  router: NextRouter
) {
  switch (actionPostAuth.actionType) {
    case ActionPostAuthType.JoinBooking:
      router.replace(
        `/explore?join-booking=${actionPostAuth.data.bookingId}&v2=${actionPostAuth.data.isV2}`
      );
      break;
    case ActionPostAuthType.GoToReservations:
      router.replace("me/reservations");
      break;
    case ActionPostAuthType.FillOutSurvey:
      router.replace({
        pathname: "/survey",
        query: actionPostAuth.data.queryParams,
      });
      break;
    case ActionPostAuthType.ConnectSlack:
      router.replace({
        pathname: "/slack",
        query: actionPostAuth.data.queryParams,
      });
      break;
    case ActionPostAuthType.ConnectMsTeams:
      router.replace({
        pathname: "/microsoft-teams",
        query: actionPostAuth.data.queryParams,
      });
      break;
    case ActionPostAuthType.ConnectRippling:
      const { variant, ...restQuery } = actionPostAuth.data.queryParams ?? {};
      router.replace({
        pathname: `/rippling/${variant || RIPPLING_PAGE_INSTALL_VARIANT}`,
        query: restQuery,
      });
      break;
    case ActionPostAuthType.RequestLocation:
      router.replace({
        pathname: "/explore",
        query: {
          [REQUEST_LOCATION_QUERY_KEY]: true,
        },
      });
      break;
    case ActionPostAuthType.RequestEvent:
      router.replace({
        pathname: "/explore",
        query: {
          [REQUEST_EVENT_QUERY_KEY]: true,
        },
      });
      break;
    case ActionPostAuthType.HqPage:
      router.replace({
        pathname: "/hq/[id]",
        query: {
          id: actionPostAuth.data.hqId,
        },
      });
      break;
    case ActionPostAuthType.myVisits:
      router.replace({
        pathname: "/me/visits",
        query: actionPostAuth.data.queryParams,
      });
      break;
    case ActionPostAuthType.EventRsvp:
      router.replace({
        pathname: "/me/events/[eventId]",
        query: {
          eventId: actionPostAuth.eventId,
        },
      });
      break;
    default:
      assertUnreachable(actionPostAuth);
      break;
  }

  clearActionPostAuth();
}
