import Head from "next/head";

export const HeadTitleTags: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Head>
      <title>{`${title} | Gable`}</title>
      <meta property="og:title" content={`${title} | Gable`} />
    </Head>
  );
};
