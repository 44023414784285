import {
  DefinedUseQueryResult,
  UseBaseQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { MutableRefObject } from "react";
import { UseFormMethods } from "react-hook-form";

export type Unpacked<T> = T extends Promise<infer U> ? U : T;
export type ArrayUnpacked<T> = T extends (infer U)[] ? U : T;

/**
 * pick specific fields of T to make them optional
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type QueryOptions<
  T extends (...args: any) => any,
  TQueryFnData = Unpacked<ReturnType<T>>
> = UseQueryOptions<Unpacked<ReturnType<T>>, unknown, TQueryFnData>;

export type InferQueryReturnType<T> = T extends QueryOptions<any, infer U>
  ? U
  : never;

export type QueryData<TQueryFunc extends (...args: any) => UseBaseQueryResult> =
  ReturnType<TQueryFunc> extends UseBaseQueryResult<infer TData>
    ? TData
    : never;

export type QueryFunctionData<T> = T extends (
  ...args: any
) => DefinedUseQueryResult<infer U>
  ? U
  : T extends (...args: any) => UseQueryResult<infer U>
  ? U
  : never;

// This function is a utils for exhaustive type checks for enum-like structures.
// It does nothing in runtime, but should fail the TS build if a case is missed.
// eslint-disable-next-line
export function assertUnreachable(_x: never) {}

export function assertEmptyObject<T extends Record<string, never>>(_x: T) {}

// This type is basically the inverse of React's ForwardedRef<T>
export type UnwrappedForwardRef<T> = T extends MutableRefObject<infer U | null>
  ? U
  : T extends (instance: infer U | null) => void
  ? U
  : null;

// Make sure that T has no keys from U
export type NoIntersection<
  T extends { [k in keyof T & keyof U]: never },
  U
> = T;

// Defines an object with at least one key.
// taken from https://stackoverflow.com/questions/48230773/how-to-create-a-partial-like-that-requires-a-single-property-to-be-set/48244432#48244432
export type AtLeastOne<T> = T &
  { [P in keyof T]: Required<Pick<T, P>> }[keyof T];

export type Never<T> = { [P in keyof T]?: never };

// Defines an object with exactly one key
// taken from https://stackoverflow.com/a/62158410
export type ExactlyOne<T, TKey = keyof T> = TKey extends keyof T
  ? { [key in Exclude<keyof T, TKey>]?: never } & { [key in TKey]: T[key] }
  : never;

export type Replace<T, P extends { [K in keyof T]?: any }> = Omit<T, keyof P> &
  P;

// This function is just Object.keys, but returns an array of the object's keys instead of strings.
// Only use this when you know there are no other keys in an object.
export function unsafeTypedKeys<T extends object>(o: T) {
  return Object.keys(o) as unknown as (keyof T)[];
}

export function unsafeTypedEntries<T extends object>(o: T) {
  return Object.entries(o) as NonNullable<
    { [Key in keyof T]: [Key, T[Key]] }[keyof T]
  >[];
}

export type InferMutationOptions<T> = T extends UseMutationResult<
  infer TData,
  infer TError,
  infer TVariables,
  infer TContext
>
  ? UseMutationOptions<TData, TError, TVariables, TContext>
  : never;

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof NonNullable<ObjectType> &
    (string | number)]: ObjectType[Key] extends (infer U)[]
    ? U extends object
      ? `${Key}.${number}.${NestedKeyOf<U>}` | `${Key}.${number}` | `${Key}`
      : `${Key}.${number}` | `${Key}`
    : ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export type NestedValue<T, K> = K extends `${infer K1}.${infer K2}`
  ? NestedValue<T[K1 & keyof T], K2>
  : T[K & keyof T];

export type FormValues<Form> = Form extends UseFormMethods<infer FieldValues>
  ? FieldValues
  : never;

export function nameOf<T extends object>() {
  // TODO: this additional function wrapper is an annoying limitation of TS. But it might be fixed soon: https://github.com/microsoft/TypeScript/issues/26242
  return <K extends keyof T>(key: K) => key;
}

export function nestedFieldOf<T extends object>() {
  return <K extends NestedKeyOf<T>>(key: K) => key;
}

// See https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type
export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;
