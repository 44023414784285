import { GableGrid } from "@gable/infra/gable-grid";
import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Link,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ConsentManagerBuilder } from "@segment/consent-manager";
import {
  CategoryPreferences,
  Destination,
} from "@segment/consent-manager/types/types";
import inEU from "@segment/in-eu";
import { captureException } from "@sentry/nextjs";
import { ComponentPropsWithoutRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Button from "../common/button";
import Switch from "../common/switch";
import Typography from "../common/typography";

// The categories and the function handleMapCustomPreferences were taken from Segment code: https://github.com/segmentio/consent-manager/blob/41b77ff45c591aae66e185531e42825bca1aefaf/src/consent-manager/index.tsx#LL243C3-L243C29
// This reproduces a similar behavior to Segment's consent manager, but with different styling.
// Although it's not in use, we prefer to keep it, in order to have a reference for the destinations, which we didn't put in the other defined categories below
const MARKETING_AND_ANALYTICS_CATEGORIES = [
  "A/B Testing",
  "Analytics",
  "Attribution",
  "Email",
  "Enrichment",
  "Heatmaps & Recordings",
  "Raw Data",
  "Realtime Dashboards",
  "Referrals",
  "Surveys",
  "Video",
];

const ADVERTISING_CATEGORIES = ["Advertising", "Tag Managers"];

const FUNCTIONAL_CATEGORIES = [
  "CRM",
  "Customer Success",
  "Deep Linking",
  "Helpdesk",
  "Livechat",
  "Performance Monitoring",
  "Personalization",
  "SMS & Push Notifications",
  "Security & Fraud",
];

const handleMapCustomPreferences = (
  destinations: Destination[],
  preferences: CategoryPreferences
) => {
  const destinationPreferences: CategoryPreferences = {};
  const customPreferences: CategoryPreferences = {};

  for (const preferenceName of Object.keys(preferences)) {
    const value = preferences[preferenceName];
    if (typeof value === "boolean") {
      customPreferences[preferenceName] = value;
    } else {
      // This is called if the initial value was null and did not change.  Will happen for users where consent is not required.
      customPreferences[preferenceName] = true;
    }
  }

  // Destinations are integrations that we enabled in Segment, such as Google Analytics, Mixpanel, Intercom, LogRocket, etc.
  // We categorize these destinations, and if a user consents to a category, all destinations under that category will be enabled.
  for (const destination of destinations) {
    if (
      ADVERTISING_CATEGORIES.find((c) => c === destination.category) &&
      destinationPreferences[destination.id] !== false
    ) {
      destinationPreferences[destination.id] = customPreferences.advertising;
    }

    if (
      FUNCTIONAL_CATEGORIES.find((c) => c === destination.category) &&
      destinationPreferences[destination.id] !== false
    ) {
      destinationPreferences[destination.id] = customPreferences.functional;
    }

    if (!(destination.id in destinationPreferences)) {
      destinationPreferences[destination.id] =
        customPreferences.marketingAndAnalytics;
    }
  }

  return { destinationPreferences, customPreferences };
};

const useStyles = makeStyles()((theme) => ({
  button: {
    borderWidth: 2,
    fontWeight: 700,
    fontSize: 16,
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "26px",
  },
}));

const ConsentCategory: React.FC<{
  categoryName: string;
  description: string;
  onChange?: (value: boolean) => void;
  value: boolean;
  alwaysOn?: boolean;
}> = ({ categoryName, description, onChange, value, alwaysOn }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    spacing={10}
    alignItems="center"
  >
    <Box>
      <Box pb={1}>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          {categoryName}
        </Typography>
      </Box>
      <Typography variant="body2">{description}</Typography>
    </Box>
    {alwaysOn ? (
      <Typography variant="body2" color="primary">
        Always On
      </Typography>
    ) : (
      <Switch checked={value ?? false} onChange={() => onChange(!value)} />
    )}
  </Stack>
);

const ConsentDrawer: ComponentPropsWithoutRef<
  typeof ConsentManagerBuilder
>["children"] = ({
  preferences,
  setPreferences,
  saveConsent,
  isConsentRequired,
  destinations,
  destinationPreferences,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isExpanded, setIsExpanded] = useState(false);

  const onSelectAll = (allState: boolean) => {
    const newPreferences = Object.keys(preferences).reduce((acc, category) => {
      acc[category] = allState;
      return acc;
    }, {});
    setPreferences(newPreferences);
    return saveConsent();
  };

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={
        isConsentRequired &&
        Object.keys(destinationPreferences ?? {}).length !==
          destinations?.length
      }
      PaperProps={{
        sx: { borderTop: 0, boxShadow: theme.shadows[1] },
      }}
    >
      <Box py={{ xs: 2, sm: 4 }} px={{ xs: 5, md: 9, lg: 20, xl: 40 }}>
        <GableGrid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={isSmallScreen ? 0 : 10}
          style={{ margin: 0 }}
        >
          <GableGrid item xs={12} md={6}>
            <Box mb={1}>
              <Typography className={classes.title}>
                We value your privacy
              </Typography>
            </Box>
            <Typography className={classes.subtitle}>
              We use cookies to enhance your browsing experience, serve
              personalized ads or content, and analyze our traffic. By clicking
              “Accept All”, you consent to our use of cookies.{" "}
              <Link
                href={`${process.env.NEXT_PUBLIC_MARKETING_BASE}/privacy-policy`}
                color={theme.palette.darkBlueGrey}
              >
                Privacy Policy
              </Link>
            </Typography>
          </GableGrid>
          <GableGrid
            item
            container
            xs={12}
            md={6}
            spacing={2}
            alignItems="center"
            style={{
              paddingLeft: isSmallScreen ? 0 : undefined,
              paddingRight: isSmallScreen ? 0 : undefined,
            }}
          >
            {isSmallScreen && (
              <GableGrid item xs={12} style={{ paddingTop: theme.spacing(3) }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  size="medium"
                  onClick={() => onSelectAll(true)}
                >
                  Accept all
                </Button>
              </GableGrid>
            )}
            <GableGrid item xs={6} md={4}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                fullWidth
                size={isSmallScreen ? "medium" : "large"}
                onClick={() => setIsExpanded((prev) => !prev)}
                endIcon={
                  <ExpandMore
                    style={{
                      transform: isExpanded ? "rotate(180deg)" : "unset",
                      transition: theme.transitions.create(["transform"], {
                        duration: 250,
                      }),
                    }}
                  />
                }
              >
                Customize
              </Button>
            </GableGrid>
            <GableGrid item xs={6} md={4}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                fullWidth
                size={isSmallScreen ? "medium" : "large"}
                onClick={() => onSelectAll(false)}
              >
                Reject all
              </Button>
            </GableGrid>
            {!isSmallScreen && (
              <GableGrid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  size="large"
                  onClick={() => onSelectAll(true)}
                >
                  Accept all
                </Button>
              </GableGrid>
            )}
          </GableGrid>
        </GableGrid>
        <Collapse in={isExpanded}>
          <Box maxWidth={800}>
            <Box py={5} pb={3}>
              <Divider />
            </Box>
            <Typography className={classes.title}>
              Website Data Collection Preferences
            </Typography>
            <Typography className={classes.subtitle}>
              Gable uses data collected by cookies and JavaScript libraries to
              improve your browsing experience, analyze site traffic, deliver
              personalized advertisements, and increase the overall performance
              of our site. By using our website, you’re agreeing to our{" "}
              <Link
                href={`${process.env.NEXT_PUBLIC_MARKETING_BASE}/privacy-policy`}
                color={theme.palette.darkBlueGrey}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
            <Stack spacing={3} mt={5}>
              <Divider />
              <ConsentCategory
                categoryName="Necessary"
                description="Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personality identifiable data."
                value={true}
                alwaysOn
              />
              <Divider />
              <ConsentCategory
                categoryName="Functional"
                description="To monitor the performance of our site and to enhance your browsing experience. For example, these tools enable you to communicate with us via live chat."
                value={preferences.functional}
                onChange={(val) => setPreferences({ functional: val })}
              />
              <Divider />
              <ConsentCategory
                categoryName="Marketing & Analytics"
                description="To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site."
                value={preferences.marketingAndAnalytics}
                onChange={(val) =>
                  setPreferences({ marketingAndAnalytics: val })
                }
              />
              <Divider />
              <ConsentCategory
                categoryName="Advertising"
                description="To personalize and measure the effectiveness of advertising on our site and other websites."
                value={preferences.advertising}
                onChange={(val) => setPreferences({ advertising: val })}
              />
            </Stack>
            <Stack
              direction={isSmallScreen ? "column" : "row"}
              spacing={2}
              pt={3}
            >
              <Button
                size={isSmallScreen ? "medium" : "large"}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => saveConsent(undefined, false)}
              >
                Save My Preferences
              </Button>
              <Button
                size={isSmallScreen ? "medium" : "large"}
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => setIsExpanded(false)}
              >
                Back
              </Button>
            </Stack>
          </Box>
        </Collapse>
      </Box>
    </Drawer>
  );
};

export const CookieDataCollectionConsent: React.FC = () => (
  <ConsentManagerBuilder
    writeKey={process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY}
    shouldRequireConsent={inEU}
    initialPreferences={{
      advertising: null,
      marketingAndAnalytics: null,
      functional: null,
    }}
    defaultDestinationBehavior="imply"
    mapCustomPreferences={handleMapCustomPreferences}
    onError={(e) => {
      captureException(e, {
        level: "warning",
        tags: { scenario: "cookie-manager-load" },
      });
    }}
  >
    {(props) => <ConsentDrawer {...props} />}
  </ConsentManagerBuilder>
);
