import { Close } from "@mui/icons-material";
import { Box, IconButton, Link } from "@mui/material";
import { useAuthContext } from "auth";
import { useRouter } from "next/router";
import { HeaderInnerHeight } from "src/components/header/header-container";
import Typography from "../common/typography";

export const HeaderCloseSection: React.FC<{
  onClose: () => void;
  showLoginPrompt?: boolean;
}> = ({ onClose, showLoginPrompt = false }) => {
  const router = useRouter();
  const auth = useAuthContext();

  const handleLoginPromptClick = async () => {
    if (!!auth.user) {
      auth.logout("/login");
      return;
    }

    router.push("/login");
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      height={HeaderInnerHeight}
    >
      {showLoginPrompt && (
        <Typography component="span">
          Already have an account?{" "}
          <Link
            onClick={handleLoginPromptClick}
            component="button"
            underline="hover"
          >
            Log in
          </Link>
        </Typography>
      )}
      <IconButton
        onClick={onClose}
        style={{
          marginLeft: 15,
          padding: 8,
          border: `1px solid #e2dfe7`,
        }}
        size="large"
      >
        <Close />
      </IconButton>
    </Box>
  );
};
