import { decomposeColor, hexToRgb, useTheme } from "@mui/material/styles";
import dynamic from "next/dynamic";
import React from "react";
import { GableColor } from "../../../theme";
import animation from "./animation.json";

const Lottie = dynamic(import("react-lottie-player/dist/LottiePlayerLight"), {
  ssr: false,
});

export interface LoadingProps {
  /**
   * Color could be either a hex code or pallete color key
   */
  color: GableColor | string;
  scale?: number;
}

export const Loading: React.FC<LoadingProps> = (props) => {
  const { color, scale = 0.5, ...rest } = props;
  const theme = useTheme();

  let hex = color;
  let animationData = animation;

  try {
    hex = theme.palette[color] ? theme.palette[color] : color;

    animationData = JSON.parse(
      JSON.stringify(animation).replaceAll(
        "0.5529411764705883,0.17647058823529413,0.9490196078431372",
        decomposeColor(hexToRgb(hex))
          .values.map((v) => v / 255)
          .join(",")
      )
    );
  } catch (error) {}

  return (
    <Lottie
      loop
      play
      animationData={animationData}
      rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
      style={{ width: 58 * scale, height: 53 * scale }}
      {...rest}
    />
  );
};

export default Loading;
