import Cloudinary, { ImageTransformOptions } from "cloudinary-tiny-js";
import { imageConfigDefault } from "next/dist/shared/lib/image-config";
import { ImageLoaderProps } from "next/image";
import { Replace } from "src/types/utils";

const allImageSizes = [
  ...imageConfigDefault.imageSizes,
  ...imageConfigDefault.deviceSizes,
];

function normalizeSize(size: number) {
  return (
    allImageSizes.find((s) => s >= size) ||
    allImageSizes[allImageSizes.length - 1]
  );
}

export function cloudinaryImageLoader({
  src,
  width,
  quality,
}: ImageLoaderProps) {
  // The image loader is used by the Next Image component, which normalizes sizes prior to calling the loader.
  return generateCloudinaryImageUrlWithNormalizedSize(src, {
    width,
    quality: quality || "auto",
    crop: "limit",
  });
}

export function generateCloudinaryImageUrl(
  imageUrl: string,
  options: Replace<
    ImageTransformOptions,
    { width?: number; height?: number }
  > = {}
) {
  if (!imageUrl) return undefined;

  const shouldNormalizeSize =
    !options?.height || options?.width === options?.height;
  const newOptions = {
    ...options,
    width:
      shouldNormalizeSize && options?.width
        ? normalizeSize(options.width)
        : options?.width,
    height:
      shouldNormalizeSize && options?.height
        ? normalizeSize(options.height)
        : options?.height,
  };

  return generateCloudinaryImageUrlWithNormalizedSize(imageUrl, newOptions);
}

function generateCloudinaryImageUrlWithNormalizedSize(
  imageUrl: string,
  options: ImageTransformOptions = {}
) {
  const newUrl = imageUrl
    .replace(
      "https://gable-s3-us-west-1-staging.s3.us-west-1.amazonaws.com/",
      "remote-media/gable-s3-us-west-1-staging/"
    )
    .replace(
      "https://s3.console.aws.amazon.com/s3/object/gable-s3-us-west-1-staging/",
      "remote-media/gable-s3-us-west-1-staging/"
    )
    .replace(
      "https://gable-s3-us-west-1-staging.s3-us-west-1.amazonaws.com/",
      "remote-media/gable-s3-us-west-1-staging/"
    )
    .replace(
      "https://gable-s3-us-west-1-prod.s3.us-west-1.amazonaws.com/",
      "remote-media/gable-s3-us-west-1-prod/"
    )
    .replace(
      "https://gable-s3-us-west-1-prod.s3-us-west-1.amazonaws.com/",
      "remote-media/gable-s3-us-west-1-prod/"
    )
    .replace("https://res.cloudinary.com/gable/image/upload/", "")
    .replace("http://res.cloudinary.com/gable/image/upload/", "");

  const cl = Cloudinary({ cloudName: "gable", secure: true });
  const cloudinaryUrl = cl(newUrl, {
    quality: "auto",
    fetchFormat: "auto",
    ...options,
  });
  return cloudinaryUrl;
}
