import { SvgIcon } from "@mui/material";
import * as React from "react";
import { IconProps } from "src/types/icon";

export const Printer: React.FC<IconProps> = ({
  variant = "contained",
  defaultFillColor = "white",
  htmlColor,
  ...props
}) => (
  <SvgIcon viewBox="0 0 27 28" {...props}>
    <g transform="translate(-654 -1208) translate(655 1209)">
      <path
        fill={htmlColor ?? "#3E484E"}
        fillRule="nonzero"
        d="M17.829-.5c.658 0 1.174.543 1.236 1.213l.006.136v5.094h-1V.849c0-.177-.091-.307-.192-.34L17.83.5h-10.4c-.102 0-.208.105-.236.265l-.007.084v5.094h-1V.849c0-.686.475-1.271 1.113-1.342l.13-.007h10.4z"
      />
      <rect
        width="14.527"
        height="9.657"
        x="5.365"
        y="16.343"
        fill={variant === "contained" ? "#F3BF2D" : defaultFillColor}
        rx="1"
      />
      <path
        fill={htmlColor ?? "#8D2DF2"}
        fillRule="nonzero"
        d="M18.892 15.843H6.365c-.828 0-1.5.671-1.5 1.5V25c0 .828.672 1.5 1.5 1.5h12.527c.828 0 1.5-.672 1.5-1.5v-7.657c0-.829-.672-1.5-1.5-1.5zm-12.527 1h12.527c.276 0 .5.224.5.5V25c0 .276-.224.5-.5.5H6.365c-.276 0-.5-.224-.5-.5v-7.657c0-.276.224-.5.5-.5zM22.657 10.271c.276 0 .5.224.5.5 0 .246-.177.45-.41.492l-.09.008H18.2c-.276 0-.5-.223-.5-.5 0-.245.177-.45.41-.492l.09-.008h4.457z"
      />
      <path
        fill={htmlColor ?? "#3E484E"}
        fillRule="nonzero"
        d="M22.286 5.443c1.853 0 3.367 1.452 3.466 3.28l.005.191v8.915c0 1.853-1.452 3.367-3.28 3.466l-.191.005h-2.447v-1h2.447c1.31 0 2.382-1.02 2.466-2.309l.005-.162V8.914c0-1.31-1.02-2.382-2.309-2.466l-.162-.005H2.97c-1.31 0-2.382 1.02-2.466 2.309L.5 8.914v8.915c0 1.31 1.02 2.382 2.309 2.466l.162.005H5.36v1H2.97c-1.853 0-3.367-1.452-3.466-3.281l-.005-.19V8.914c0-1.853 1.452-3.367 3.281-3.466l.19-.005h19.315z"
      />
    </g>
  </SvgIcon>
);
