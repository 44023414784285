import { captureException } from "@sentry/nextjs";
import { useQueryClient } from "@tanstack/react-query";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { clearRecentSearchItems } from "../src/components/app/search/search-utils";
import { useGetUserAndCompany } from "../src/queries/get-user";
import {
  PostLoginRouteConfig,
  User,
  defaultPostLoginRouteConfig,
} from "../src/types/auth-types";
import {
  clearActionPostAuth,
  getActionPostAuth,
  handleActionPostAuth,
} from "../src/utils/action-post-auth";
export interface AuthType {
  performPostLoginFlow?: (routeToDirectToConfig: PostLoginRouteConfig) => void;
  logout?: (directAfterLogoutRoute?: string | null) => Promise<void>;
  refreshUser?: () => Promise<User | void>;
  updateUserParams?: (params: Partial<User>) => void;
  user?: User;
  isUserDataLoading?: boolean;
  isNotLoggedIn?: boolean;
}

interface AuthContextProviderProps {
  children?: React.ReactNode;
}

const AuthContext = createContext<AuthType>({});

export default function AuthContextProvider(props: AuthContextProviderProps) {
  const { status: sessionStatus } = useSession();
  const router = useRouter();

  const queryClient = useQueryClient();
  const {
    data: userData,
    refetch: refetchUser,
    isLoading,
  } = useGetUserAndCompany();

  const performPostLoginFlow = useCallback(
    (routeToDirectToConfig: PostLoginRouteConfig) => {
      refetchUser({ throwOnError: true })
        .then(({ data: { user } }) => {
          const actionPostAuth = getActionPostAuth();
          if (actionPostAuth && user.signUpStage === 2) {
            handleActionPostAuth(actionPostAuth, router);
            return;
          }
          const route =
            routeToDirectToConfig[user.type] ??
            routeToDirectToConfig["fallback"];
          router.replace(route);
        })
        .catch((err) => {
          captureException(err);
          router.replace("/explore");
        });
    },
    [router]
  );

  const logout = useCallback(async (directAfterLogoutRoute = "/login") => {
    await signOut({ redirect: false });
    clearActionPostAuth();
    clearRecentSearchItems();
    queryClient.removeQueries();
    analytics.reset();
    analytics.ready(function () {
      window.Intercom?.("shutdown");
      window.Intercom?.("boot", undefined);
      window.mixpanel?.reset();
    });
    directAfterLogoutRoute !== null && router.push(directAfterLogoutRoute);
  }, []);

  const refreshUser = useCallback(async () => {
    return refetchUser({ throwOnError: true })
      .then(({ data: { user } }) => user)
      .catch((error) => {
        captureException(error);
        throw error;
      });
  }, []);

  const updateUserParams = useCallback((params: Partial<User>) => {
    queryClient.setQueryData(
      [useGetUserAndCompany.queryKey],
      (userData: ReturnType<typeof useGetUserAndCompany>["data"]) => ({
        ...userData,
        user: { ...userData.user, ...params },
      })
    );
  }, []);

  useEffect(() => {
    // If query params contain token or flow, we reached the client following a login flow.
    // Middleware should handle this login flow, so if we reached the client with the parameters they are no longer needed.
    // This useEffect clears the unnecessary parameters.
    if (router.query.token || router.query.flow) {
      const { token, flow, ...rest } = router.query;
      if (router.query.flow === "post_login") {
        performPostLoginFlow(defaultPostLoginRouteConfig);
        return;
      }
      router.replace(
        {
          pathname: router.pathname,
          query: rest,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router.query.token, router.query.flow]);

  return (
    <AuthContext.Provider
      value={{
        user: userData?.user,
        isUserDataLoading: isLoading,
        performPostLoginFlow,
        logout,
        updateUserParams,
        refreshUser,
        isNotLoggedIn: sessionStatus === "unauthenticated",
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
