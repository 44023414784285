import {
  Box,
  BoxProps,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";

import { Close } from "@mui/icons-material";

export const ACTION_DIALOG_HEADER_HEIGHT = 40 + 16;

interface ActionDialogProps extends DialogProps {
  open: boolean;
  onClose: (
    reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick"
  ) => void;
  disableClose?: boolean;
  transparentHeader?: boolean;
  backdropColor?: string;
  boxProps?: BoxProps;
}

const ActionDialog: React.FC<ActionDialogProps> = ({
  open,
  onClose,
  disableClose,
  transparentHeader,
  backdropColor,
  children,
  boxProps,
  ...rest
}) => (
  <>
    <Dialog
      open={open}
      onClose={(e, reason) => onClose(reason)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        variant: "elevation",
        style: {
          maxWidth: 710,
        },
      }}
      slotProps={{ backdrop: { sx: { backgroundColor: backdropColor } } }}
      {...rest}
    >
      <Box
        {...boxProps}
        sx={[
          { overflowY: "auto" },
          ...(Array.isArray(boxProps?.sx) ? boxProps.sx : [boxProps?.sx]),
        ]}
      >
        <Box
          height={ACTION_DIALOG_HEADER_HEIGHT}
          pt={2}
          pr={2}
          sx={transparentHeader ? { backgroundColor: "transparent" } : {}}
        >
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              onClick={() => onClose("closeButtonClick")}
              disabled={disableClose}
              style={{ padding: 8 }}
              size="large"
            >
              <Close />
            </IconButton>
          </Stack>
        </Box>
        {children}
      </Box>
    </Dialog>
  </>
);

export default ActionDialog;
