import { SvgIcon } from "@mui/material";
import * as React from "react";
import { IconProps } from "src/types/icon";

export const PhoneCallArea: React.FC<IconProps> = ({
  variant = "contained",
  defaultFillColor = "white",
  htmlColor,
  ...props
}) => (
  <SvgIcon viewBox="0 0 27 28" {...props}>
    <path
      fill={variant === "contained" ? "#F3BF2D" : defaultFillColor}
      d="M7.094 13.861C2.904 14.237 0 15.052 0 15.997c0 1.302 5.506 2.357 12.299 2.357 6.792 0 12.298-1.055 12.298-2.357 0-1.008-3.302-1.868-7.948-2.205.096.568.096.955 0 1.161-.112.243-.422.53-.932.86H8.853c-.515-.3-.895-.587-1.141-.86-.145-.16-.351-.524-.618-1.092z"
      transform="translate(-359 -1206) translate(360 1206.501)"
    />
    <path
      stroke={htmlColor ?? "#3E484E"}
      d="M7.094 13.861C2.904 14.237 0 15.052 0 15.997c0 1.302 5.506 2.357 12.299 2.357 6.792 0 12.298-1.055 12.298-2.357 0-1.008-3.302-1.868-7.948-2.205"
      transform="translate(-359 -1206) translate(360 1206.501)"
    />
    <path
      stroke={htmlColor ?? "#3E484E"}
      d="M0 15.997v1.661c0 1.426 5.506 2.582 12.299 2.582 6.792 0 12.298-1.156 12.298-2.582v-1.616"
      transform="translate(-359 -1206) translate(360 1206.501)"
    />
    <path
      fill={htmlColor ?? "#3E484E"}
      fillRule="nonzero"
      d="M4.384 20.092c.082-.263.362-.411.626-.33.234.072.377.301.349.537l-.019.088-2.034 6.6c-.081.263-.361.411-.625.33-.235-.072-.378-.302-.35-.537l.02-.088 2.033-6.6z"
      transform="translate(-359 -1206) translate(360 1206.501)"
    />
    <path
      fill={htmlColor ?? "#3E484E"}
      fillRule="nonzero"
      d="M21.145 20.092c.081-.263.36-.411.625-.33.234.072.377.301.349.537l-.019.088-2.034 6.6c-.081.263-.36.411-.625.33-.234-.072-.377-.302-.35-.537l.02-.088 2.034-6.6z"
      transform="translate(-359 -1206) translate(360 1206.501) matrix(-1 0 0 1 41.21 0)"
    />
    <path
      fill={htmlColor ?? "#8D2DF2"}
      fillRule="nonzero"
      d="M4.106 21.36l.09.003 16.77 2.033c.274.033.47.283.436.557-.03.243-.23.425-.466.439l-.09-.003-16.77-2.034c-.274-.033-.47-.282-.436-.556.03-.244.23-.425.466-.44z"
      transform="translate(-359 -1206) translate(360 1206.501)"
    />
    <path
      fill={htmlColor ?? "#8D2DF2"}
      fillRule="nonzero"
      d="M20.165 21.363c.274-.034.523.162.557.436.03.243-.122.467-.348.538l-.088.018L3.545 24.39c-.274.033-.524-.162-.557-.436-.03-.244.122-.468.348-.538l.088-.019 16.741-2.033z"
      transform="translate(-359 -1206) translate(360 1206.501)"
    />
    <g fill={htmlColor ?? "#8D2DF2"} fillRule="nonzero">
      <path
        d="M7.51-.5H2.2C.715-.5-.5.714-.5 2.201v11.411c0 1.487 1.214 2.7 2.7 2.7h5.31c1.486 0 2.7-1.213 2.7-2.7V2.202C10.21.713 8.996-.5 7.51-.5zM2.2.5h5.31c.934 0 1.7.766 1.7 1.701v11.411c0 .935-.766 1.7-1.7 1.7H2.2c-.934 0-1.7-.765-1.7-1.7V2.202C.5 1.266 1.266.5 2.2.5z"
        transform="translate(-359 -1206) translate(360 1206.501) translate(7.1)"
      />
      <path
        d="M4.745 12.7c-.623.061-1.078.616-1.018 1.24.06.621.615 1.077 1.239 1.017.622-.061 1.078-.615 1.017-1.238-.057-.582-.543-1.018-1.114-1.024l-.124.005zM5.996 1.025c.277 0 .5.224.5.5 0 .246-.176.45-.41.492l-.09.008H3.973c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492l.09-.008h2.023z"
        transform="translate(-359 -1206) translate(360 1206.501) translate(7.1)"
      />
    </g>
  </SvgIcon>
);
