import type { FilterOptionsState } from "@mui/material";
import type { SpaceContainer } from "src/apis/space-containers";
import { AutocompleteGeoLocation } from "src/components/common/locations-autocomplete";
import { COUNTRIES } from "src/utils/countries";
import type { GeoLocation } from "../../../apis/locations";
import { isSpaceContainer, type Space } from "../../../apis/spaces";

export type SearchableSpace =
  | Pick<
      SpaceContainer,
      "_id" | "title" | "city" | "state" | "country" | "isCompanyOffice"
    >
  | Pick<
      Space,
      | "_id"
      | "title"
      | "city"
      | "state"
      | "country"
      | "isCompanyOffice"
      | "container"
    >;

export interface SearchableGeoLocation
  extends Pick<GeoLocation, "city" | "formattedName" | "urlSlug"> {}

interface RecentSearchItemBase {
  type: "Space" | "Location";
}

type RecentSpaceSearchItem = RecentSearchItemBase &
  SearchableSpace & {
    type: "Space";
  };

interface RecentLocationSearchItem
  extends RecentSearchItemBase,
    SearchableGeoLocation {
  type: "Location";
}

type RecentSearchItem = RecentSpaceSearchItem | RecentLocationSearchItem;

const recentSearchItemsKey = "recentSearches";

export function getRecentSearchItems(): RecentSearchItem[] {
  if (typeof localStorage === "undefined" || localStorage === null) return [];
  const result = JSON.parse(localStorage.getItem(recentSearchItemsKey));
  if (!Array.isArray(result)) {
    return [];
  }
  return result;
}

function setRecentSearchItems(items: RecentSearchItem[]) {
  if (typeof localStorage === "undefined" || localStorage === null) return [];
  localStorage.setItem(recentSearchItemsKey, JSON.stringify(items));
}

export function clearRecentSearchItems() {
  if (typeof localStorage === "undefined" || localStorage === null) return;
  localStorage.removeItem(recentSearchItemsKey);
}

export function filterLocationsForAutoComplete<
  TLocation extends AutocompleteGeoLocation
>(locations: TLocation[], state: FilterOptionsState<TLocation>) {
  return filterLocationsBySearchTerm(locations, state?.inputValue);
}

export function filterLocationsBySearchTerm<
  TLocation extends AutocompleteGeoLocation
>(locations: TLocation[], searchTerm: string): TLocation[] {
  if (!searchTerm || !locations?.length) {
    return locations || [];
  }
  const searchTermLowerCase = searchTerm.toLowerCase();
  const filteredLocations = locations.filter(
    (option) =>
      option.cityLowercase.includes(searchTermLowerCase) ||
      option.cityShortName?.toLowerCase().includes(searchTermLowerCase) ||
      option.formattedName?.toLowerCase().includes(searchTermLowerCase) ||
      option.state?.toLowerCase().includes(searchTermLowerCase) ||
      option.stateShortName?.toLowerCase().includes(searchTermLowerCase) ||
      !!option.aliases?.find((alias) =>
        alias?.toLowerCase().includes(searchTermLowerCase)
      ) ||
      (option.country?.toLowerCase().includes(searchTermLowerCase) &&
        option.country?.toLowerCase() !==
          COUNTRIES.UnitedStates.toLocaleLowerCase())
  );

  const getIndexOfSearchTerm = (field1?: string, field2?: string) => {
    const index1 = field1
      ? field1.toLowerCase().indexOf(searchTermLowerCase)
      : -1;
    const index2 = field2
      ? field2.toLowerCase().indexOf(searchTermLowerCase)
      : -1;
    if (index1 === -1 && index2 === -1) {
      return Number.MAX_VALUE;
    }
    if (index1 !== -1 && index2 !== -1) {
      return Math.min(index1, index2);
    }
    return Math.max(index1, index2);
  };

  return filteredLocations.sort((a, b) => {
    return (
      getIndexOfSearchTerm(a.cityLowercase, a.cityShortName) -
        getIndexOfSearchTerm(b.cityLowercase, b.cityShortName) ||
      getIndexOfSearchTerm(a.formattedName) -
        getIndexOfSearchTerm(b.formattedName) ||
      getIndexOfSearchTerm(a.state, a.stateShortName) -
        getIndexOfSearchTerm(b.state, b.stateShortName) ||
      getIndexOfSearchTerm(a.country) - getIndexOfSearchTerm(b.country)
    );
  });
}

export function saveRecentSpaceSearch(item: SearchableSpace) {
  const items = getRecentSearchItems();
  if (items.find((i) => i.type === "Space" && i._id === item._id)) return;
  saveRecentItem({
    ...(isSpaceContainer(item)
      ? {
          _id: item._id,
        }
      : {
          container: item.container,
          _id: item._id,
        }),
    type: "Space",
    title: item.title,
    city: item.city,
    state: item.state,
    country: item.country,
    isCompanyOffice: item.isCompanyOffice,
  });
}

export function saveRecentLocationSearch(item: SearchableGeoLocation) {
  const items = getRecentSearchItems();
  if (
    items.find(
      (i) =>
        i.type === "Location" &&
        i.urlSlug?.toLowerCase() === item.urlSlug?.toLowerCase()
    )
  )
    return;
  saveRecentItem({
    type: "Location",
    city: item.city,
    formattedName: item.formattedName,
    urlSlug: item.urlSlug,
  });
}

function saveRecentItem(item: RecentSearchItem) {
  const items = getRecentSearchItems();
  const newItems: RecentSearchItem[] = [item, ...items.slice(0, 4)];
  setRecentSearchItems(newItems);
}
