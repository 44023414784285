import { Box, BoxProps } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<Pick<Props, "direction" | "height">>()(
  (theme, { direction, height }) => ({
    root: {
      height: height ? height : "20vh",
      clipPath: `polygon(${clipPathDirection[direction]})`,
      marginBottom: -1,
      zIndex: 1,
      position: "relative",

      [theme.breakpoints.down("md")]: {
        height: height ? height : 80,
      },
    },
  })
);

const clipPathDirection = {
  "top right": "100% 100%, 0 0, 100% 0",
  "top left": "100% 0, 0% 100%, 0 0",
  "bottom right": "100% 0, 0% 100%, 100% 100%",
  "bottom left": "0 101%, 0 0, 101% 101%",
};

interface Props extends BoxProps {
  direction: keyof typeof clipPathDirection;
  bgcolor?: string;
  height?: string | number;
}

const Triangle: React.FC<Props> = ({
  direction = "top right",
  bgcolor = "#F7F2EF",
  height,
  ...rest
}) => {
  const { classes } = useStyles({ direction, height });
  return <Box className={classes.root} bgcolor={bgcolor} {...rest} />;
};

export default Triangle;
